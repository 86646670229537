import { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import isApprovedRedirect from 'utils/redirectWhitelist'

import { enqueueError } from 'lib/components/GlobalNotifications'
import { setRedirectUrl, setRedirectLabel } from 'actions/redirectActions'

/**
 * This component is responsible for checking the current url for redirect params.
 * If redirect params are found they are written to redux state. The redirect_url
 * param is validated against a whitelist; if it fails validation an error notification
 * is shown to the user.
 */
class RedirectParamsHandler extends Component {
  componentDidMount() {
    let { location, setRedirectUrl, setRedirectLabel } = this.props
    let params = queryString.parse(location.search)

    if (params.redirect_url) setRedirectUrl(decodeURIComponent(params.redirect_url))
    if (params.redirect_label) setRedirectLabel(decodeURIComponent(params.redirect_label))
  }

  validateRedirect() {
    let { enqueueError, redirectUrl } = this.props

    if (redirectUrl) {
      try {
        if (!isApprovedRedirect(redirectUrl))
          enqueueError({
            message:
              'The provided redirect is not approved for use with this application.',
          })
      } catch (error) {
        enqueueError({ message: 'There is a problem with the provided redirect.' })
      }
    }

    return null
  }

  render() {
    return this.validateRedirect()
  }
}

const mapDispatchToProps = {
  enqueueError,
  setRedirectLabel,
  setRedirectUrl,
}

export { RedirectParamsHandler }
export default compose(
  connect(state => {
    return {
      redirectUrl: state.redirect.url,
    }
  }, mapDispatchToProps),
  withRouter
)(RedirectParamsHandler)
