import React from 'react'
import { connect } from 'react-redux'

const ProductBreadcrumb = ({ product }) => <span>{product ? product.name : ''}</span>

export { ProductBreadcrumb }
export default connect(state => {
  return {
    product: state.product.data,
  }
})(ProductBreadcrumb)
