import React, { Component } from 'react'
import { connect } from 'react-redux'

import Notification from '../Notification'
import NotificationModal from '../NotificationModal'
import { dequeueNotification } from '../actions/notificationsActions'

class ReduxNotificationController extends Component {
  constructor() {
    super()
    this.handleClose = this.handleClose.bind(this)
  }

  handleClose() {
    this.props.dequeueNotification()
  }

  render() {
    let { notifications } = this.props
    let notification = notifications[0] || null

    return notification ? (
      <NotificationModal>
        <Notification notification={notification} onClose={this.handleClose} />
      </NotificationModal>
    ) : null
  }
}

const mapDispatchToProps = {
  dequeueNotification,
}

export { ReduxNotificationController }
export default connect(state => {
  return {
    notifications: state.notifications,
  }
}, mapDispatchToProps)(ReduxNotificationController)
