import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import axios from 'lib/axios'
import { proxyUrl } from 'lib/utils/request'

import { setCustomerContext } from 'actions/customerContextActions'
import { enqueueFlashNotification } from 'lib/components/FlashNotifications/actions/flashNotificationsActions'
import { getPartyLink } from 'lib/selectors/linkSelectors'

import ReactModal from 'react-modal'

import styles from './styles.scss'

const customStyles = {
  overlay: {
    animation: 'modal-in 0.15s ease-out 0s backwards',
    backgroundColor: 'rgba(0, 30, 98, .5)',
    zIndex: '4',
  },
}

class FsmHandler extends Component {
  constructor(props) {
    super(props)

    this.state = {
      notPermittedToOrderForOrganisation: false,
    }
  }

  componentDidMount() {
    let {
      enqueueFlashNotification,
      location: { search },
      profile,
    } = this.props
    let params = queryString.parse(search)
    let organisationLink = params.organisation_id
    let personLink = params.person_id

    if (organisationLink) {
      this.checkFsmHasAccessToOrganisation()
    }

    if (!organisationLink || !personLink) {
      let detailComponent = (
        <p>
          Welcome {profile.preferred_name || profile.preferred_username}. You are browsing
          with no customer selected.
          <span>
            &nbsp;<a href={global.config.LIC_CUSTOMER_ENDPOINT}>Select a Customer</a> to
            place an order.
          </span>
        </p>
      )

      enqueueFlashNotification({ id: 'fsm_browsing', detailComponent })
    }
  }

  checkFsmHasAccessToOrganisation() {
    let {
      partyLink,
      location: { search },
      setCustomerContext,
    } = this.props
    let params = queryString.parse(search)
    let organisationLink = params.organisation_id
    let personLink = params.person_id

    let url = proxyUrl({ link: `${global.config.TEAM_SERVICE_ENDPOINT}/api/query` })
    let body = {
      _type: 'team_ql',
      resources: [
        {
          match: 'identifier',
          term: organisationLink,
        },
      ],
      subjects: [
        {
          match: 'identifier',
          term: partyLink,
        },
      ],
    }

    axios.post(url, body).then(response => {
      let orgItems = response.data.items
      let orgItem = orgItems.find(
        orgItem => orgItem.resource.identifier === organisationLink
      )
      if (orgItem) {
        let canOrderForOrg =
          orgItem.subject.permission === 'urn:lic:team:permission:write' ||
          orgItem.subject.permission === 'urn:lic:team:permission:admin'
        this.setState({ notPermittedToOrderForOrganisation: !canOrderForOrg })
        setCustomerContext({
          party_link: personLink,
          organisation_party_link: organisationLink,
        })
      }
    })
  }

  render() {
    let { notPermittedToOrderForOrganisation } = this.state
    let customerSearchLink = `${global.config.LIC_CUSTOMER_ENDPOINT}/#/customers`

    return notPermittedToOrderForOrganisation ? (
      <ReactModal
        isOpen={notPermittedToOrderForOrganisation}
        contentLabel='FSM Errors'
        style={customStyles}
        className={styles.content}
        ariaHideApp={false}
      >
        <p>Something went wrong setting up your shopping session:</p>
        <p>You are not permitted to order for that organisation.</p>
        <p>
          Return to <a href={customerSearchLink}>Customer search</a> to choose a customer.
        </p>
      </ReactModal>
    ) : null
  }
}

const mapDispatchToProps = {
  enqueueFlashNotification,
  setCustomerContext,
}

export { FsmHandler }
export default compose(
  connect(state => {
    return {
      profile: state.profile.data,
      partyLink: getPartyLink(state.profile.data),
    }
  }, mapDispatchToProps),
  withRouter
)(FsmHandler)
