function isApprovedRedirect(url) {
  const REDIRECT_WHITELIST = [
    global.config.LIC_CUSTOMER_ENDPOINT,
    'http://localhost:5012',
    //TODO remove Shop Assistant once it has had customer search removed
    global.config.SHOP_ASSISTANT_FRONTEND_ENDPOINT,
    'http://localhost:5013',
  ]

  return REDIRECT_WHITELIST.includes(new URL(url).origin)
}

export default isApprovedRedirect
