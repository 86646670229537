import createReducer from 'lib/reducers/createReducer'
import { lastProductSearchTypes } from 'actions/types'

const INITIAL_STATE = null

function dataHandler(state, action) {
  return action.payload || INITIAL_STATE
}

const reducer = createReducer(INITIAL_STATE, {
  [lastProductSearchTypes.SET_LAST_PRODUCT_SEARCH]: dataHandler,
})

export default reducer
