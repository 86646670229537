import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { href } from '@licnz/js-utils'
import { withRouter } from 'react-router-dom'

import { clearBasket, deleteBasket } from 'actions/basketActions'
import { clearCustomerContext } from 'actions/customerContextActions'
import { clearOperation } from 'actions/operationActions'
import { clearOrganisation } from 'actions/organisationActions'
import { clearBillingEntity } from 'actions/billingEntityActions'
import {
  dequeueFlashNotification,
  enqueueFlashNotification,
} from 'lib/components/FlashNotifications/actions/flashNotificationsActions'
import { enqueueError } from 'lib/components/GlobalNotifications'
import { fetchOrganisations } from 'actions/organisationsActions'
import { getPartyLink } from 'lib/selectors/linkSelectors'
import { setCustomerContext } from 'actions/customerContextActions'

import Button from 'lib/components/Button'
import ConfirmationModal from 'lib/components/ConfirmationModal'
import Dropdown from 'lib/components/Dropdown'
import ReactModal from 'react-modal'

import styles from './styles.scss'

const customStyles = {
  overlay: {
    animation: 'modal-in 0.15s ease-out 0s backwards',
    backgroundColor: 'rgba(0, 30, 98, .5)',
    zIndex: '4',
  },
}

class CustomerHandler extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showResetContextModal: false,
      mustSelectOrganisation: false,
      selectedOrganisation: null,
    }

    this.handleOrganisationSelect = this.handleOrganisationSelect.bind(this)
    this.handleResetContext = this.handleResetContext.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleToggleResetContextModal = this.handleToggleResetContextModal.bind(this)
  }

  componentDidMount() {
    let { enqueueError, fetchOrganisations, partyLink } = this.props

    if (partyLink) {
      fetchOrganisations(partyLink).catch(() =>
        enqueueError({
          message:
            'We were unable to fetch any organisations. Please contact LIC on 0800 2 MINDA (0800 264 632).',
        })
      )
    }
  }

  componentDidUpdate(prevProps) {
    let {
      dequeueFlashNotification,
      enqueueError,
      enqueueFlashNotification,
      fetchOrganisations,
      operation,
      operations,
      organisation,
      organisations,
      partyLink,
      profile,
      setCustomerContext,
    } = this.props

    if (!prevProps.partyLink && partyLink) {
      fetchOrganisations(partyLink).catch(() =>
        enqueueError({
          message:
            'We were unable to fetch any organisations. Please contact LIC on 0800 2 MINDA (0800 264 632).',
        })
      )
    }

    if (prevProps.organisations !== organisations) {
      if (!organisations.length) {
        enqueueError({
          message:
            "It looks like you're not associated with any Organisations. Please contact LIC on 0800 2 MINDA (0800 264 632).",
        })
      } else {
        let organisationLink = href({ links: organisations[0].links, rel: 'self' })

        if (organisations.length === 1) {
          setCustomerContext({
            organisation_party_link: organisationLink,
            party_link: partyLink,
          })
        } else {
          let organisationLink = href({ links: organisations[0].links, rel: 'self' })
          this.setState({
            mustSelectOrganisation: true,
            selectedOrganisation: organisationLink,
          })
        }
      }
    }

    if (
      (prevProps.organisation !== organisation || prevProps.operation !== operation) &&
      organisation &&
      operation
    ) {
      let contextSnippets = [organisation.metadata.name]
      if (operation) contextSnippets.push(`(${operation.metadata.handle})`)

      let resetLink = () => {
        if (operations.length > 1 || organisations.length > 1) {
          return (
            <span>
              {' '}
              <a onClick={this.handleToggleResetContextModal}>Reset</a>
            </span>
          )
        }
      }

      let detailComponent = (
        <p>
          Welcome {profile.preferred_name || profile.preferred_username}. You are shopping
          for {contextSnippets.join(' ')}.{resetLink()}
        </p>
      )

      enqueueFlashNotification({ id: 'customer_shopping', detailComponent })
    }

    if (prevProps.organisation !== organisation && !organisation) {
      dequeueFlashNotification({ id: 'customer_shopping' })
      this.setState({ mustSelectOrganisation: true })
    }
  }

  handleResetContext() {
    let {
      basket,
      clearBasket,
      clearOperation,
      clearOrganisation,
      clearBillingEntity,
      clearCustomerContext,
      deleteBasket,
      history,
    } = this.props

    if (basket) {
      let basketLink = href({ links: basket.links, rel: 'self' })
      deleteBasket(basketLink)
      clearBasket()
    }

    clearOperation()
    clearOrganisation()
    clearBillingEntity()
    clearCustomerContext()

    this.handleToggleResetContextModal()

    history.push('/')
  }

  handleToggleResetContextModal() {
    this.setState({ showResetContextModal: !this.state.showResetContextModal })
  }

  renderConfirmResetContext() {
    let { basket } = this.props

    let confirmationText = ['You are about to switch your farming operation.']
    if (basket && basket.requested_products.length) {
      confirmationText.push('This will delete all products in the basket.')
    }
    confirmationText.push('Do you wish to continue?')

    return (
      <ConfirmationModal
        onClose={this.handleToggleResetContextModal}
        onConfirm={this.handleResetContext}
        showModal={this.state.showResetContextModal}
      >
        {confirmationText.join(' ')}
      </ConfirmationModal>
    )
  }

  handleOrganisationSelect(organisationLink) {
    this.setState({ selectedOrganisation: organisationLink })
  }

  handleSubmit() {
    let { selectedOrganisation } = this.state
    let { partyLink, setCustomerContext } = this.props

    this.setState({ mustSelectOrganisation: false })
    setCustomerContext({
      party_link: partyLink,
      organisation_party_link: selectedOrganisation,
    })
  }

  renderOrganisationSelect() {
    let { selectedOrganisation } = this.state
    let organisationOptions = this.props.organisations.map(org => {
      let contextSnippets = [org.metadata.name]
      let operationHandles = org.metadata.operation_handles
      if (operationHandles && operationHandles.length)
        contextSnippets.push(`(${operationHandles.join(', ')})`)
      return {
        id: href({ links: org.links, rel: 'self' }),
        name: contextSnippets.join(' '),
      }
    })

    return (
      <ReactModal
        isOpen={this.state.mustSelectOrganisation}
        contentLabel='Organisation Select'
        className={styles.content}
        style={customStyles}
        ariaHideApp={false}
      >
        <p>Which Organisation would you like to order for?</p>
        <Dropdown
          onSelect={this.handleOrganisationSelect}
          options={organisationOptions}
          selectedOptionId={selectedOrganisation}
          selectOptionText='Select an organisation...'
        />
        <Button className='buttonSecondary buttonBlock' onClick={this.handleSubmit}>
          Start Shopping
        </Button>
      </ReactModal>
    )
  }

  render() {
    if (this.state.mustSelectOrganisation) {
      return this.renderOrganisationSelect()
    } else if (this.state.showResetContextModal) {
      return this.renderConfirmResetContext()
    } else return null
  }
}

const mapDispatchToProps = {
  clearBasket,
  clearCustomerContext,
  clearOperation,
  clearOrganisation,
  clearBillingEntity,
  deleteBasket,
  dequeueFlashNotification,
  enqueueError,
  enqueueFlashNotification,
  fetchOrganisations,
  setCustomerContext,
}

export { CustomerHandler }
export default compose(
  withRouter,
  connect(state => {
    return {
      basket: state.basket.data,
      customerContext: state.customerContext.data,
      operation: state.customerContext.operation.data,
      operations: state.operations.data,
      organisation: state.customerContext.organisation.data,
      organisations: state.organisations.data,
      partyLink: getPartyLink(state.profile.data),
      profile: state.profile.data,
    }
  }, mapDispatchToProps)
)(CustomerHandler)
