import React from 'react'

import styles from './styles.scss'

const SiteFooter = () => {
  return (
    <div className={styles.siteFooter}>
      <div className={styles.gutters}>
        <div className={styles.grid}>
          <div className={styles.linksWidth}>
            <ul className={styles.listInline}>
              <li className={styles.listInlineItem}>
                <a
                  href='https://www.lic.co.nz/about/lic-service-rules/'
                  className={styles.footerMenuLink}
                >
                  LIC Service Rules
                </a>
              </li>
              <li className={styles.listInlineItem}>
                <a
                  href='https://www.lic.co.nz/contact-lic/feedback/'
                  className={styles.footerMenuLink}
                >
                  Feedback and complaints
                </a>
              </li>
              <li className={styles.listInlineItem}>
                <a
                  href='https://www.lic.co.nz/about/privacy/'
                  className={styles.footerMenuLink}
                >
                  Privacy
                </a>
              </li>
              <li className={styles.listInlineItem}>
                <a
                  href='https://www.lic.co.nz/licshop/'
                  className={styles.footerMenuLink}
                >
                  FAQ
                </a>
              </li>
              <li className={styles.listInlineItem}>
                <a
                  href='https://www.lic.co.nz/about/terms-use/'
                  className={styles.footerMenuLink}
                >
                  Terms of use
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.dateWrapper}>
            <p className={styles.date}>
              © {new Date().getFullYear()} Copyright LIC • All rights reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SiteFooter
