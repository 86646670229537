import React, { useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'

import { customerContextIsComplete } from 'selectors/customerContextSelectors'

import Login from 'lib/components/Login'
import UserDropdown from '@licnz/react-user-dropdown'
import BasketDropdown from './BasketDropdown'
import BasketIcon from 'lib/components/BasketIcon'
import SearchBarContainer from './SearchBarContainer'
import SearchTrigger from 'lib/components/SearchTrigger'
import AppNav from './AppNav'
import AppNavSmall from './AppNavSmall'

import styles from './styles.scss'

const AppBar = (props) => {
  const [showSearchBar, setShowSearchBar] = useState(false)
  const [showSmallMenu, setShowSmallMenu] = useState(false)
  

  const handleToggleSearchBar = () => {
    setShowSearchBar(!showSearchBar)
  }

  const handleToggleSmallMenu = () => {
    setShowSmallMenu(!showSmallMenu)
  }

  const userDropdownOptions = () => {
    return [
      { label: 'My profile', link: `${global.config.LIC_CUSTOMER_ENDPOINT}/#/profile` },
      { label: 'Teams', link: `${global.config.LIC_CUSTOMER_ENDPOINT}/#/teams` },
      {
        label: 'Log out',
        link: `${global.config.IDENTITY_FRONTEND_ENDPOINT}/logout?logout_redirect_uri=${global.config.UI_PROXY_ENDPOINT}/logout`,
      },
    ]
  }

  const renderUserDropdown = () => {
    let { isLoggedIn, profile } = props
    let name = profile ? profile.preferred_name || profile.preferred_username : null

    return isLoggedIn ? (
      <div className={styles.dropdown}>
        <UserDropdown userName={name} menuOptions={userDropdownOptions()} />
      </div>
    ) : (
      <Login />
    )
  }

  const basketDropdownOptions = () => {
    let { basket } = props
    let basketCount = basket ? basket.requested_products_total_quantity : null

    let options = [{ label: 'My Orders', link: '/orders' }]

    let basketLabel = `Basket${basketCount ? ` (${basketCount})` : ''}`
    options.unshift({ label: basketLabel, link: '/basket' })

    return options
  }

  const renderBasketDropdown = () => {
    let { basket, isLoggedIn, customerContextIsComplete } = props
    let basketCount = basket ? basket.requested_products_total_quantity : null

    return (
      isLoggedIn &&
      customerContextIsComplete && (
        <div className={styles.dropdown}>
          <BasketDropdown
            options={basketDropdownOptions()}
            basketCount={basketCount}
          />
        </div>
      )
    )
  }

  const renderBasketIcon = () => {
    let { basket, isLoggedIn, customerContextIsComplete } = props
    let basketCount = basket ? basket.requested_products_total_quantity : null

    return (
      isLoggedIn &&
      customerContextIsComplete && (
        <BasketIcon onClick={handleBasketIconClick} basketCount={basketCount} />
      )
    )
  }

  const handleBasketIconClick = () => {
    props.history.push('/basket')
  }

  
    let { basket, location, showNavigation } = props

    let { q } = queryString.parse(location.search)
    let logoPath = '/images/shop-logo.svg'
    let basketCount = basket ? basket.requested_products_total_quantity : null

    let headerMenuSecondaryClass = showSearchBar
      ? styles.headerMenuSecondaryHide
      : styles.headerMenuSecondaryShow
    let overlayClass = showSearchBar ? styles.overlayShow : styles.overlayHide

    return (
      <>
        <div className={styles.header}>
          <div className={styles.gutters}>
            <div className={styles.headerContent}>
              <a className={styles.headerLogo} href='/'>
                <img src={logoPath} alt='LIC Logo' />
              </a>
              {showNavigation ? (
                <div className={styles.headerMenu}>
                  <div className={styles.headerMenuLarge}>
                    <SearchBarContainer
                      q={q || ''}
                      handleClose={handleToggleSearchBar}
                      showSearchBar={showSearchBar}
                    />
                    <AppNav />
                    <div
                      className={`${styles.headerMenuSecondary} ${headerMenuSecondaryClass}`}
                    >
                      <SearchTrigger handleOpen={handleToggleSearchBar} />
                      {renderUserDropdown()}
                      {renderBasketDropdown()}
                    </div>
                  </div>
                  <div className={styles.menuSmall}>
                    {renderBasketIcon()}
                    <a
                      onClick={handleToggleSmallMenu}
                      className={`${styles.menuSmallTrigger} ${
                        showSmallMenu && styles.triggerOpen
                      }`}
                    >
                      <span className={styles.menuSmallTriggerText}>Menu</span>
                      <span className={styles.menuSmallTriggerDetailGroup}>
                        <span className={styles.menuSmallTriggerDetail}></span>
                        <span className={styles.menuSmallTriggerDetail}></span>
                        <span className={styles.menuSmallTriggerDetail}></span>
                      </span>
                    </a>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {showNavigation ? (
          <AppNavSmall
            basketCount={basketCount}
            showSmallMenu={showSmallMenu}
            handleClose={handleToggleSmallMenu}
          />
        ) : null}
        <div className={`${styles.overlay} ${overlayClass}`}></div>
      </>
    )
  
}

export { AppBar }
export default compose(
  withRouter,
  connect(state => {
    return {
      basket: state.basket.data,
      customerContextIsComplete: customerContextIsComplete(state),
      isLoggedIn: state.loggedIn.data.isLoggedIn,
      profile: state.profile.data,
      showNavigation: !state.downForMaintenance,
    }
  })
)(AppBar)
