import { combineReducers } from 'redux'
import createReducer from 'lib/reducers/createReducer'
import operationReducer from './operationReducer'
import organisationReducer from './organisationReducer'
import personReducer from './personReducer'
import personProfileReducer from './personProfileReducer'
import requiresOperationReducer from './requiresOperationReducer'
import billingEntityReducer from './billingEntityReducer'
import customAddressesReducer from './customAddressesReducer'
import { customerContextTypes } from 'actions/types'

const INITIAL_STATE = null

function dataHandler(state, action) {
  return action.payload ? { ...state, ...action.payload } : INITIAL_STATE
}

const dataReducer = createReducer(INITIAL_STATE, {
  [customerContextTypes.CLEAR_CUSTOMER_CONTEXT]: dataHandler,
  [customerContextTypes.SET_CUSTOMER_CONTEXT]: dataHandler,
})

const reducer = combineReducers({
  requiresOperation: requiresOperationReducer,
  data: dataReducer,
  operation: operationReducer,
  organisation: organisationReducer,
  person: personReducer,
  personProfile: personProfileReducer,
  billingEntity: billingEntityReducer,
  customAddresses: customAddressesReducer,
})

export default reducer
