import { Component } from 'react'
import { connect } from 'react-redux'
import { change } from 'redux-form'

class ReduxFormDropdown extends Component {
  constructor(props) {
    super(props)
    this.handleSelect = this.handleSelect.bind(this)
  }

  handleSelect(id) {
    let { change, formName, fieldName } = this.props
    change(formName, fieldName, id)
  }

  render() {
    let { render, selectedOptionId } = this.props
    return render(this.handleSelect, selectedOptionId)
  }
}

const mapStateToProps = (state, props) => {
  let { selector } = props
  return {
    selectedOptionId: selector(state),
  }
}

const mapDispatchToProps = { change }

export { ReduxFormDropdown }
export default connect(mapStateToProps, mapDispatchToProps)(ReduxFormDropdown)
