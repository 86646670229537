import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'

import SearchBar from 'lib/components/SearchBar'

class SearchBarContainer extends Component {
  constructor() {
    super()

    this.state = {
      q: '',
      page: 1,
      per_page: 20,
    }

    this.handleResetSearchQuery = this.handleResetSearchQuery.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    let { q } = this.props
    this.setState({ q })
  }

  componentDidUpdate(prevProps) {
    let { q } = this.props

    if (prevProps.q !== q) {
      this.setState({ q })
    }
  }

  updateUrl(updateParams) {
    let { location } = this.props
    let currentParams = queryString.parse(location.search)

    if (updateParams.q === '') {
      delete updateParams.q
      delete currentParams.q
    }

    let params = { ...currentParams, ...updateParams }

    this.props.history.push(`/search?${queryString.stringify(params)}`)
  }

  handleSearchChange(q) {
    this.setState({ q })
  }

  handleResetSearchQuery() {
    this.setState({ q: '' })
  }

  handleSubmit() {
    let { q, page, per_page } = this.state
    this.updateUrl({ q, page, per_page })
  }

  render() {
    let { handleClose, showSearchBar } = this.props
    let { q } = this.state

    return showSearchBar ? (
      <SearchBar
        onChange={this.handleSearchChange}
        onClose={handleClose}
        onReset={this.handleResetSearchQuery}
        onSubmit={this.handleSubmit}
        animate
        showCloseIcon
        value={q}
      />
    ) : null
  }
}

export { SearchBarContainer }
export default withRouter(SearchBarContainer)
