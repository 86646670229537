import { post } from 'lib/reduxHelpers/thunks'
import { operationsTypes } from 'actions/types'
import { proxyUrl } from 'lib/utils/request'

function fetchOperations(organisationLink) {
  const baseType = operationsTypes.FETCH_OPERATIONS
  const url = proxyUrl({
    link: `${global.config.RELATIONSHIP_SERVICE_ENDPOINT}/api/v1/parties/match`,
  })
  const body = {
    query: {
      match: {
        identifiers: [
          {
            id: organisationLink,
            '@type': 'party_link',
          },
        ],
        parties: [
          {
            '@type': 'operation',
            distance: 1,
            direction: 'down',
          },
        ],
      },
    },
  }

  return post({ baseType, url, body, extractPayload: response => response.data.parties })
}

function searchOperations(organisationLink) {
  const baseType = operationsTypes.FETCH_OPERATIONS
  const url = proxyUrl({
    link: `${global.config.RELATIONSHIP_SERVICE_ENDPOINT}/api/v1/relationships/search`,
  })
  const body = {
    to: {
      query: {
        match: { id: organisationLink },
        kind: 'organisation',
      },
    },
    depth: 3,
    rel: {
      rel: ['urn:lic:graph:rel:farming_operation'],
    },
  }
  const extractPayload = response => {
    return response.data.relationships.graph.nodes.filter(
      node => node.kind === 'operation'
    )
  }

  return post({ baseType, url, body, extractPayload })
}

export { fetchOperations, searchOperations }
