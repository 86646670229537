import React from 'react'
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import Main from 'lib/components/layout/Main'
import Banner from 'lib/components/layout/Banner'
import PageHeadingSection from 'lib/components/PageHeadingSection'

const text = (
  <>
    Please try <NavLink to='/search'>Searching</NavLink> or visit the{' '}
    <NavLink to='/'>Homepage</NavLink> to start over.
  </>
)

const PageNotFound = () => {
  return (
    <>
      <Main>
        <Banner />
        <PageHeadingSection
          heading="Oops! That page doesn't seem to exist."
          text={text}
        />
      </Main>
      <Helmet defer={false}>
        <title>Page Not Found</title>
      </Helmet>
    </>
  )
}

export default PageNotFound
