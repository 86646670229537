import { combineReducers } from 'redux'
import createReducer from 'lib/reducers/createReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'

import { reservationTypes } from 'actions/types'

const INITIAL_STATE = null

function dataHandler(state, action) {
  if (action.type === reservationTypes.CANCEL_BIRTH_IDS_RESERVATION_FULFILLED)
    return INITIAL_STATE
  else return action.payload || INITIAL_STATE
}

const dataReducer = createReducer(INITIAL_STATE, {
  [reservationTypes.CANCEL_BIRTH_IDS_RESERVATION_FULFILLED]: dataHandler,
  [reservationTypes.CLEAR_FETCH_BIRTH_IDS_RESERVATION]: dataHandler,
  [reservationTypes.FETCH_BIRTH_IDS_RESERVATION_FULFILLED]: dataHandler,
})

const reducer = combineReducers({
  data: dataReducer,
  requestState: requestStateReducer(reservationTypes.FETCH_BIRTH_IDS_RESERVATION),
  createRequestState: requestStateReducer(reservationTypes.CREATE_BIRTH_IDS_RESERVATION),
  cancelRequestState: requestStateReducer(reservationTypes.CANCEL_BIRTH_IDS_RESERVATION),
})

export default reducer
