import { post } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/request'

import { organisationsTypes } from 'actions/types'

function fetchOrganisations(personPartyLink) {
  const baseType = organisationsTypes.FETCH_ORGANISATIONS
  const url = proxyUrl({
    link: `${global.config.RELATIONSHIP_SERVICE_ENDPOINT}/api/v1/parties/match`,
  })
  const body = {
    query: {
      match: {
        identifiers: [
          {
            id: personPartyLink,
            '@type': 'party_link',
          },
        ],
        parties: [
          {
            '@type': 'organisation',
            distance: 2,
          },
        ],
      },
    },
  }

  return post({ baseType, url, body, extractPayload: response => response.data.parties })
}

export { fetchOrganisations }
