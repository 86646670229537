import { combineReducers } from 'redux'
import { customerContextTypes } from 'actions/types'
import createReducer from 'lib/reducers/createReducer'

const INITIAL_STATE = false

function dataHandler(state, action) {
  return action.payload || INITIAL_STATE
}

const dataReducer = createReducer(INITIAL_STATE, {
  [customerContextTypes.SET_REQUIRES_OPERATION]: dataHandler,
})

const reducer = combineReducers({
  data: dataReducer,
})

export default reducer
