import { combineReducers } from 'redux'
import createReducer from 'lib/reducers/createReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import shippingRateReducer from './shippingRateReducer'

import { basketTypes } from 'actions/types'

const INITIAL_STATE = null

function dataHandler(state, action) {
  if (action.type == basketTypes.FETCH_BASKET_FULFILLED && action.payload) {
    let requestedProducts = action.payload.requested_products
    requestedProducts &&
      requestedProducts.sort((a, b) => {
        return a.name < b.name ? -1 : 1
      })
  }
  return action.payload || INITIAL_STATE
}

const dataReducer = createReducer(INITIAL_STATE, {
  [basketTypes.FETCH_BASKET_FULFILLED]: dataHandler,
  [basketTypes.CLEAR_FETCH_BASKET]: dataHandler,
  [basketTypes.CREATE_BASKET_FULFILLED]: dataHandler,
  [basketTypes.CLEAR_CREATE_BASKET]: dataHandler,
  [basketTypes.UPDATE_BASKET_FULFILLED]: dataHandler,
  [basketTypes.CLEAR_UPDATE_BASKET]: dataHandler,
})

const reducer = combineReducers({
  data: dataReducer,
  requestState: requestStateReducer(basketTypes.FETCH_BASKET),
  createRequestState: requestStateReducer(basketTypes.CREATE_BASKET),
  updateRequestState: requestStateReducer(basketTypes.UPDATE_BASKET),
  shippingRate: shippingRateReducer,
})

export default reducer
