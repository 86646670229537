import { get } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/request'

import { profileTypes } from 'actions/types'

function fetchProfile() {
  let link = `${global.config.PROFILE_SERVICE_ENDPOINT}/api/profiles/for_me`
  let url = proxyUrl({ link })
  let baseType = profileTypes.FETCH_PROFILE

  return get({ url, baseType })
}

export { fetchProfile }
