import React from 'react'
import CylinderSpinLoader from 'lib/components/CylinderSpinLoader'
import styles from './styles.scss'

const LoadingIndicatorOverlay = () => (
  <div className={styles.screen_overlay}>
    <div className={styles.spinner_wrapper}>
      <CylinderSpinLoader color={'#001e62'} />
    </div>
  </div>
)
export default LoadingIndicatorOverlay
