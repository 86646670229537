import { createSelector } from 'reselect'
import { href } from '@licnz/js-utils'

const getAddressesLink = createSelector([data => data], data => {
  return data ? href({ links: data.links, rel: 'addresses' }) : null
})

const getPartyLink = createSelector([data => data], data => {
  return data ? href({ links: data.links, rel: 'party' }) : null
})

const getPaymentMethodsLink = createSelector([data => data], data => {
  return data ? href({ links: data.links, rel: 'payment_methods' }) : null
})

const getSelfLink = createSelector([data => data], data => {
  return data ? href({ links: data.links, rel: 'self' }) : null
})

export { getAddressesLink, getPartyLink, getPaymentMethodsLink, getSelfLink }
