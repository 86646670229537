import { connectRouter } from 'connected-react-router'

import agOp from 'reducers/agOpReducer'
import animalGroup from 'reducers/animalGroupReducer'
import availableTagNumbers from 'reducers/availableTagNumbersReducer'
import basket from 'reducers/basketReducer'
import billingEntities from 'reducers/billingEntitiesReducer'
import creditValidity from 'reducers/creditValidityReducer'
import customerContext from 'reducers/customerContextReducer'
import downForMaintenance from 'lib/reducers/downForMaintenanceReducer'
import { flashNotificationsReducer as flashNotifications } from 'lib/components/FlashNotifications'
import { reducer as form } from 'redux-form'
import herds from 'reducers/herdsReducer'
import lastProductSearch from 'reducers/lastProductSearchReducer'
import loggedIn from 'lib/reducers/loggedInReducer'
import { notificationsReducer as notifications } from 'lib/components/GlobalNotifications'
import operations from 'reducers/operationsReducer'
import order from 'reducers/orderReducer'
import orderedProduct from 'reducers/orderedProductReducer'
import orders from 'reducers/ordersReducer'
import organisations from 'reducers/organisationsReducer'
import permissions from 'reducers/permissionsReducer'
import product from 'reducers/productReducer'
import productConfigurationRequirements from 'reducers/productConfigurationRequirementsReducer'
import products from 'reducers/productsReducer'
import profile from 'reducers/profileReducer'
import rating from 'reducers/ratingReducer'
import redirect from 'reducers/redirectReducer'
import requestedProduct from 'reducers/requestedProductReducer'

import isDummyTag from 'reducers/dummyTagsReducer'

export default history => {
  return {
    agOp,
    animalGroup,
    availableTagNumbers,
    basket,
    billingEntities,
    creditValidity,
    customerContext,
    downForMaintenance,
    form,
    herds,
    lastProductSearch,
    loggedIn,
    flashNotifications,
    notifications,
    operations,
    order,
    orderedProduct,
    orders,
    organisations,
    permissions,
    product,
    productConfigurationRequirements,
    products,
    profile,
    rating,
    redirect,
    requestedProduct,
    isDummyTag,
    router: connectRouter(history),
  }
}
