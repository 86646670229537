import { actionTypes } from '../constants'

function enqueueFlashNotification({ id, title, detailComponent }) {
  let payload = { id, title, detailComponent }

  return {
    type: actionTypes.ENQUEUE_FLASH_NOTIFICATION,
    payload,
  }
}

function dequeueFlashNotification({ id }) {
  let payload = { id }

  return {
    type: actionTypes.DEQUEUE_FLASH_NOTIFICATION,
    payload,
  }
}

export { enqueueFlashNotification, dequeueFlashNotification }
