import { combineReducers } from 'redux'
import createReducer from 'lib/reducers/createReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import { requestedProductTypes } from 'actions/types'

import reservationsReducer from './reservationsReducer'

const INITIAL_STATE = null

function dataHandler(state, action) {
  return action.payload || INITIAL_STATE
}

const dataReducer = createReducer(INITIAL_STATE, {
  [requestedProductTypes.FETCH_REQUESTED_PRODUCT_FULFILLED]: dataHandler,
  [requestedProductTypes.CLEAR_FETCH_REQUESTED_PRODUCT]: dataHandler,
  [requestedProductTypes.CREATE_REQUESTED_PRODUCT_FULFILLED]: dataHandler,
  [requestedProductTypes.CLEAR_CREATE_REQUESTED_PRODUCT]: dataHandler,
  [requestedProductTypes.UPDATE_REQUESTED_PRODUCT_FULFILLED]: dataHandler,
  [requestedProductTypes.CLEAR_UPDATE_REQUESTED_PRODUCT]: dataHandler,
  [requestedProductTypes.SET_REQUESTED_PRODUCT]: dataHandler,
  [requestedProductTypes.CLEAR_REQUESTED_PRODUCT]: dataHandler,
})

const reducer = combineReducers({
  createRequestState: requestStateReducer(requestedProductTypes.CREATE_REQUESTED_PRODUCT),
  deleteRequestState: requestStateReducer(requestedProductTypes.DELETE_REQUESTED_PRODUCT),
  requestState: requestStateReducer(requestedProductTypes.FETCH_REQUESTED_PRODUCT),
  updateRequestState: requestStateReducer(requestedProductTypes.UPDATE_REQUESTED_PRODUCT),
  data: dataReducer,
  reservations: reservationsReducer,
})

export default reducer
