import React from 'react'
import PropTypes from 'prop-types'
import urlPropType from 'url-prop-type'
import { headerTextConstants } from '..'

import Button from 'lib/components/Button'

import styles from './styles.scss'

const Notification = ({ notification, onClose }) => {
  let { message, ctaLabel, ctaLink, type } = notification
  let defaultMessage =
    'Something went wrong, please try again later. If the problem persists, please contact LIC on 0800 2 MINDA (0800 264 632)'

  let headerText = () => headerTextConstants[type]

  return (
    <div className={styles.modal}>
      <header className={styles.header}>
        <span>{headerText()}</span>
      </header>

      <p className={styles.paragraph}>
        {message || defaultMessage}
        {ctaLabel && ctaLink ? (
          <span>
            &nbsp;<a href={ctaLink}>{ctaLabel}</a>
          </span>
        ) : null}
      </p>

      <div className={styles.controls}>
        <div className={styles.buttonContainer}>
          <Button className='buttonSecondary buttonBlock' autoFocus onClick={onClose}>
            Ok
          </Button>
        </div>
      </div>
    </div>
  )
}

// Custom prop validation to support conditional prop checking
const requiredWhenDetailIsPresent = type => {
  return (props, propName) => {
    if (props.notification.detail) {
      if (props[propName] == undefined) {
        return new Error(
          `${propName} is required when the notification has the 'detail' attribute`
        )
      } else if (typeof props[propName] != type) {
        return new Error(
          `expected ${propName} of type '${type}' but received ${typeof props[propName]}`
        )
      }
    }
  }
}

Notification.propTypes = {
  notification: PropTypes.shape({
    message: PropTypes.string.isRequired,
    ctaLabel: PropTypes.string,
    ctaLink: urlPropType,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onToggleDetail: requiredWhenDetailIsPresent('function'),
  showDetail: requiredWhenDetailIsPresent('boolean'),
}

export default Notification
