import createReducer from 'lib/reducers/createReducer'
import { productConfigurationRequirementsTypes } from 'actions/types'
import { updateDuplicatedConfigPaths } from 'utils/productConfiguration'

const INITIAL_STATE = {}

const dataHandler = (state, action) => {
  if (action.payload) {
    let { urn, addPath, removePath, requirements } = action.payload
    let data =
      requirements ||
      updateDuplicatedConfigPaths({
        urn,
        addPath,
        removePath,
        currentRequirements: state,
      })
    return data || INITIAL_STATE
  } else {
    return INITIAL_STATE
  }
}

const reducer = createReducer(INITIAL_STATE, {
  [productConfigurationRequirementsTypes.CLEAR_MANAGEMENT_NUMBER_REQUIREMENTS]: dataHandler,
  [productConfigurationRequirementsTypes.UPDATE_MANAGEMENT_NUMBER_REQUIREMENTS]: dataHandler,
})

export default reducer
