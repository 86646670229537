import React from 'react'
import PropTypes from 'prop-types'
import CylinderSpinLoader from 'lib/components/CylinderSpinLoader'

import styles from '../styles.scss'

const Button = ({
  className,
  disabled,
  onClick,
  type,
  name,
  'data-testid': dataTestId,
}) => {
  const setClasses = () => {
    return (
      className &&
      className
        .split(' ')
        .map(buttonClass => styles[buttonClass])
        .join(' ')
    )
  }

  return (
    <button
      data-testid={dataTestId}
      name={name}
      type={type || 'button'}
      role='button'
      className={setClasses()}
      onClick={onClick}
      disabled={disabled}
    >
      <CylinderSpinLoader color='#fff' size={3} />
    </button>
  )
}

Button.propTypes = {
  className: (props, propName, componentName) => {
    let error
    const prop = props[propName]
    if (prop === undefined) {
      return
    }
    if (typeof prop !== 'string')
      error = new Error(
        `'${componentName}' expected className of type 'string'. Received ${prop}`
      )
    else {
      prop.split(' ').forEach(token => {
        const validClasses = [
          'buttonPrimary',
          'buttonSecondary',
          'buttonOutline',
          'buttonOutlineInverted',
          'buttonSmall',
          'buttonMedium',
          'buttonLarge',
          'buttonBlock',
          'buttonLink',
        ]
        if (!validClasses.includes(token)) {
          error = new Error(
            `'${componentName}' expects all classNames to be one of ${validClasses}. Received ${token}`
          )
        }
      })
    }
    return error
  },
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string,
}

export default Button
