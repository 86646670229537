import { get } from 'lib/reduxHelpers/thunks'
import queryString from 'query-string'

import { permissionsTypes } from 'actions/types'

function fetchPermissions() {
  let params = { activity: 'lic:shop:privilege:impersonations:impersonate' }
  let url = `${
    global.config.UI_PROXY_ENDPOINT
  }/api/permitted_activity?${queryString.stringify(params)}`
  let baseType = permissionsTypes.FETCH_PERMISSIONS

  return get({ url, baseType })
}

export { fetchPermissions }
