import { post } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/request'

import { billingEntitiesTypes } from 'actions/types'

function fetchBillingEntities(organisationPartyLink) {
  let url = proxyUrl({
    link: `${global.config.ACCOUNT_SERVICE_ENDPOINT}/billing_entities/search`,
  })
  let baseType = billingEntitiesTypes.FETCH_BILLING_ENTITIES
  let body = { directives: '_asc', party_identity: organisationPartyLink }

  return post({ url, baseType, body, extractPayload: response => response.data.items })
}

export { fetchBillingEntities }
