import { destroy, get, post } from 'lib/reduxHelpers/thunks'
import { href } from '@licnz/js-utils'
import { proxyUrl } from 'lib/utils/request'
import { basketTypes } from 'actions/types'

function createBasket({ billingEntity, operation, organisation }) {
  let baseType = basketTypes.CREATE_BASKET
  let link = `${global.config.ORDER_SERVICE_ENDPOINT}/basket`
  let url = proxyUrl({ link })

  let organisationName = organisation.metadata.name
  let organisationLink = href({ links: organisation.links, rel: 'self' })
  let billingEntityName = billingEntity.name
  let billToLink = href({ links: billingEntity.links, rel: 'self' })
  let operationName = operation.metadata.name
  let operationLink = href({ links: operation.links, rel: 'self' })

  let body = {
    _type: 'basket',
    'urn:lic:predicate:order_purchaser': {
      name: organisationName,
      identifier: {
        _type: 'urn:lic:id:party',
        identifier: organisationLink,
      },
    },
    'urn:lic:predicate:bill_to_party': {
      name: billingEntityName,
      identifier: {
        _type: 'urn:lic:id:billing_entity',
        identifier: billToLink,
      },
    },
    'urn:lic:predicate:assign_to_party': {
      name: operationName,
      identifier: {
        _type: 'urn:lic:id:party',
        identifier: operationLink,
      },
    },
  }

  return post({ baseType, url, body })
}

function setAssignToParty({ basketLink, operation }) {
  //TODO: get rid of this hack once we have a basket.link to the endpoint
  let url = proxyUrl({ link: `${basketLink}/set_assign_to_party` })
  let operationName = operation.metadata.name
  let operationLink = href({ links: operation.links, rel: 'self' })

  let body = {
    _type: 'party',
    'urn:lic:predicate:assign_to_party': {
      name: operationName,
      identifier: {
        _type: 'urn:lic:id:party',
        identifier: operationLink,
      },
    },
  }
  let baseType = basketTypes.UPDATE_BASKET

  return post({ baseType, url, body })
}

function fetchBasket(customerContext) {
  if (customerContext) {
    let baseType = basketTypes.FETCH_BASKET
    let purchasing_party_link = customerContext.organisation_party_link
    let assign_to_party_link = customerContext.assign_to_link
    let link = `${global.config.ORDER_SERVICE_ENDPOINT}/basket/find`
    let url = proxyUrl({ link, params: { purchasing_party_link, assign_to_party_link } })

    return get({ baseType, url })
  }
}

function deleteBasket(link) {
  let baseType = basketTypes.DELETE_BASKET
  let url = proxyUrl({ link })

  return destroy({ baseType, url })
}

function clearBasket() {
  return function (dispatch) {
    dispatch({ type: basketTypes.CLEAR_CREATE_BASKET })
    dispatch({ type: basketTypes.CLEAR_FETCH_BASKET })
  }
}

export { clearBasket, createBasket, deleteBasket, fetchBasket, setAssignToParty }
