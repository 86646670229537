import { createSelector } from 'reselect'

const customerContextIsComplete = createSelector([data => data], state => {
  if (!state.customerContext.data) return false

  let { party_link, organisation_party_link, bill_to_link } = state.customerContext.data
  return party_link && organisation_party_link && bill_to_link
})

const canFetchBasket = createSelector([data => data], state => {
  if (!state.customerContext.data) return false

  let { party_link, organisation_party_link, assign_to_link } = state.customerContext.data
  return party_link && organisation_party_link && assign_to_link
})

export { canFetchBasket, customerContextIsComplete }
