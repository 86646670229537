import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter, NavLink } from 'react-router-dom'

import productFamilies from 'constants/productFamilies'
import queryString from 'query-string'

import Gutters from 'lib/components/layout/Gutters'

import styles from './styles.scss'

class AppNavSmall extends Component {
  userOptions() {
    return [
      { label: 'My profile', link: `${global.config.LIC_CUSTOMER_ENDPOINT}/#/profile` },
      { label: 'Basket', link: '/basket', 'data-count': this.props.basketCount },
      { label: 'My orders', link: '/orders' },
      {
        label: 'Log out',
        link: `${global.config.IDENTITY_FRONTEND_ENDPOINT}/logout?logout_redirect_uri=${global.config.UI_PROXY_ENDPOINT}/logout`,
      },
    ]
  }

  renderUserDetails() {
    let { isLoggedIn, profile, handleClose, location } = this.props
    let name = profile ? profile.preferred_name || profile.preferred_username : null

    let currentQueryParams = queryString.parse(location.search)
    let currentPath = encodeURIComponent(location.pathname)
    let params = queryString.stringify(
      { ...currentQueryParams, path: currentPath },
      { arrayFormat: 'bracket' }
    )

    const loginUrl = `${global.config.UI_PROXY_ENDPOINT}/proxy/connect/identity?${params}`

    return isLoggedIn ? (
      <Fragment>
        <li className={styles.dropdownItemHeader}>
          <span>{name}</span>
        </li>
        {this.userOptions().map(option => {
          return (
            <li
              onClick={handleClose}
              className={styles.dropdownItem}
              key={option.link}
              data-count={option['data-count'] && option['data-count']}
            >
              {this.optionComponent(option)}
            </li>
          )
        })}
      </Fragment>
    ) : (
      <li className={styles.dropdownItemHeader}>
        <span>
          <a className={styles.dropdownLink} href={loginUrl}>
            Sign in
          </a>
        </span>
      </li>
    )
  }

  isExternalLink(link) {
    return link.includes('http://') || link.includes('https://')
  }

  optionComponent(option) {
    return this.isExternalLink(option.link) ? (
      <a className={styles.dropdownLink} href={option.link}>
        {option.label}
      </a>
    ) : (
      <NavLink className={styles.dropdownLink} to={option.link}>
        {option.label}
      </NavLink>
    )
  }

  render() {
    let { showSmallMenu, handleClose } = this.props

    return (
      <div
        className={`${styles.menuSmallDropdownGroup} ${
          showSmallMenu && styles.menuSmallExpanded
        }`}
      >
        <Gutters noGuttersSmall={false}>
          <div className={styles.menuSmallHeader}>
            <a
              onClick={handleClose}
              className={`${styles.menuSmallTrigger} ${
                showSmallMenu && styles.triggerOpen
              }`}
            >
              <span className={styles.menuSmallTriggerText}>Close</span>
              <span className={styles.menuSmallTriggerDetailGroup}>
                <span className={styles.menuSmallTriggerDetail}></span>
                <span className={styles.menuSmallTriggerDetail}></span>
                <span className={styles.menuSmallTriggerDetail}></span>
              </span>
            </a>
          </div>
          <div className={styles.menuSmallDropdown}>
            <ul className={styles.listUnstyled}>
              {this.renderUserDetails()}
              <li className={styles.dropdownItemHeader}>
                <span>Shop for</span>
              </li>
              {Object.values(productFamilies)
                .filter(productFamily => productFamily.enabled)
                .map(item => {
                  return (
                    <li
                      onClick={handleClose}
                      className={styles.dropdownItem}
                      key={item.urn}
                    >
                      <NavLink className={styles.dropdownLink} to={item.route}>
                        {item.title}
                      </NavLink>
                    </li>
                  )
                })}
              <li onClick={handleClose} className={styles.dropdownItem}>
                <NavLink className={styles.dropdownLink} to='/search'>
                  <svg className={styles.searchIcon}>
                    <use xlinkHref='/images/sprites.svg#search' />
                  </svg>
                  Search
                </NavLink>
              </li>
            </ul>
          </div>
        </Gutters>
      </div>
    )
  }
}

AppNavSmall.propTypes = {
  showSmallMenu: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
}

export { AppNavSmall }
export default compose(
  withRouter,
  connect(state => {
    return {
      isLoggedIn: state.loggedIn.data.isLoggedIn,
      profile: state.profile.data,
    }
  })
)(AppNavSmall)
