import React, { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { Helmet } from 'react-helmet'
import productFamilies from 'constants/productFamilies'

import Section from 'lib/components/layout/Section'
import Gutters from 'lib/components/layout/Gutters'
import ProductBreadcrumb from './ProductBreadcrumb'
import OrderBreadcrumb from './OrderBreadcrumb'
import OrderedProductBreadcrumb from './OrderedProductBreadcrumb'

import styles from './styles.scss'

/**
 * This creates a separate breadcrumb for each Product family, even though these views
 * are really just a Products page filtered by family. Without this, our breadcrumb would
 * simply show 'Products' instead of 'Tags' or 'Calf Packs' etc.
 */
const customBreadcrumbRoutes = (() => {
  let familyRoutes = Object.values(productFamilies)
    .filter(productFamily => productFamily.enabled)
    .map(family => family.route.split('/')[1])
  let productRoutes = {
    path: `/(${familyRoutes.join('|')})/:id`,
    breadcrumb: ProductBreadcrumb,
  }
  let productsRoutes = Object.values(productFamilies)
    .filter(productFamily => productFamily.enabled)
    .map(family => {
      return { path: family.route, breadcrumb: family.title }
    })

  return [
    productRoutes,
    ...productsRoutes,
    { path: '/(basket|search)/:id', breadcrumb: ProductBreadcrumb },
    { path: '/orders/:orderId/orderedProduct', breadcrumb: null },
    {
      path: '/orders/:orderId/orderedProduct/:orderedProductId',
      breadcrumb: OrderedProductBreadcrumb,
    },
    { path: '/orders/:id', breadcrumb: OrderBreadcrumb },
  ]
})()

const breadcrumbLink = (match, location) => {
  let url = match.url
  let search = location.search

  if (search) {
    url += search
  }
  return url
}

const Breadcrumbs = () => {
  const ref = useRef(null)
  const [title, setTitle] = useState('LIC Shop')
  const breadcrumbs = useBreadcrumbs(customBreadcrumbRoutes)

  useEffect(() => {
    if (ref.current) {
      setTitle(
        ref.current.textContent
          .replace('Home', 'LIC Shop')
          .split('/')
          .reverse()
          .join(' | ')
      )
    }
  }, [])

  return (
    <>
      <Helmet defer={false}>
        <title>{title}</title>
      </Helmet>
      {breadcrumbs.length > 1 && (
        <Section small>
          <Gutters>
            <ul ref={ref} className={`${styles.listUnstyled} ${styles.breadcrumb}`}>
              {breadcrumbs.map(({ breadcrumb, match, location }, index) => (
                <li key={breadcrumb.key} className={styles.breadcrumbItem}>
                  {/* Don't render a link for the last breadcrumb */}
                  {index === breadcrumbs.length - 1 ? (
                    <span className={styles.breadcrumbLink}>{breadcrumb}</span>
                  ) : (
                    <NavLink
                      to={breadcrumbLink(match, location)}
                      className={styles.breadcrumbLink}
                    >
                      {breadcrumb}
                    </NavLink>
                  )}
                  {index < breadcrumbs.length - 1 && (
                    <span className={styles.breadcrumbDivider}>/</span>
                  )}
                </li>
              ))}
            </ul>
          </Gutters>
        </Section>
      )}
    </>
  )
}

export { Breadcrumbs }
export default Breadcrumbs
