import React, { Fragment } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import axios from 'axios'
import Bowser from 'bowser'
import { intializeMonitoring } from './utils/monitoringClient'
import { FEATURE_FLAGS } from './utils/featureFlags'
import AppRoot from './AppRoot'
import UnsupportedBrowser from 'components/UnsupportedBrowser'
import createStore from './store/createStore'

const getAppConfig = () => {
  return new Promise((resolve, reject) => {
    // timestamp is a timestamp. Set to the current time to force a re-read of the config. In the future this will be done via deploy scripts
    axios
      .get('/config.json?timestamp=1589765113')
      .then(response => {
        return resolve(response.data)
      })
      .catch(err => {
        return reject(err)
      })
  })
}

const gtmScript = (w, d, s, l, i) => {
  w[l] = w[l] || []
  w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != 'dataLayer' ? '&l=' + l : ''
  j.async = true
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
  f.parentNode.insertBefore(j, f)
}

getAppConfig().then(config => {
  global.config = config

  // set feature flags
  global.FEATURE_FLAGS = FEATURE_FLAGS

  const browser = Bowser.getParser(window.navigator.userAgent)
  const isValidBrowser = browser.satisfies({
    chrome: '>=71',
    firefox: '>=63',
    safari: '>=10',
  })

  intializeMonitoring()

  const root = createRoot(document.querySelector('#root'))

  root.render(
    <Fragment>
      {/* Google Tag Manager script */}
      <script>
        {gtmScript(
          window,
          document,
          'script',
          'dataLayer',
          global.config.SHOP_GTM_CONTAINER_ID
        )}
      </script>
      {/* Google Tag Manager noscript */}
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${global.config.SHOP_GTM_CONTAINER_ID}`}
          style={{ height: 0, width: 0, display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
      {isValidBrowser ? (
        <Provider store={createStore()}>
          <AppRoot />
        </Provider>
      ) : (
        <UnsupportedBrowser />
      )}
    </Fragment>
  )
})
