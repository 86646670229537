import React from 'react'
import { connect } from 'react-redux'

import { Breadcrumbs } from 'components/Routes'

import styles from './styles.scss'

const Banner = ({ showBreadcrumbs = false }) => {
  return (
    <div className={styles.banner} data-testid='banner'>
      <div className={styles.bannerStripe}>{showBreadcrumbs && <Breadcrumbs />}</div>
    </div>
  )
}

export { Banner }
export default connect(state => {
  return {
    showBreadcrumbs: !state.downForMaintenance,
  }
})(Banner)
