import React from 'react'
import PropTypes from 'prop-types'

import TitleUnderline from 'lib/components/TitleUnderline'

import styles from './styles.scss'

function PageHeading({ heading, subheading }) {
  return (
    <div className={styles.lineLength}>
      <TitleUnderline>{heading}</TitleUnderline>
      {subheading && <h4 className={styles.subheading}>{subheading}</h4>}
    </div>
  )
}

PageHeading.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default PageHeading
