export const dataLoad = () => {
  return {
    eventType: 'dataLoad',
    payload: 'action.payload',
  }
}

export const isAuthenticated = ({ payload: { isLoggedIn } }) => {
  return { isLoggedIn, isImpersonating: false }
}

export const setVariableTo = (name, value) => () => ({ [name]: value })

export const setUserProfileVars = ({ payload }) => {
  const { provider, preferred_username } = payload

  return {
    username: preferred_username,
    identityProvider: provider,
  }
}

export const setOperationHandle = ({ payload }) => {
  return {
    operationHandle: payload.metadata.handle,
  }
}