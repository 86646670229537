import { actionTypes, notificationTypes } from '../constants'

function enqueueNotification({ message, ctaLabel, ctaLink, type }) {
  let payload = { message, ctaLabel, ctaLink, type }

  return {
    type: actionTypes.ENQUEUE_NOTIFICATION,
    payload,
  }
}

function enqueueError({ message, ctaLabel, ctaLink }) {
  return enqueueNotification({
    message,
    ctaLabel,
    ctaLink,
    type: notificationTypes.ERROR,
  })
}

function enqueueInfo({ message }) {
  return enqueueNotification({ message, type: notificationTypes.INFO })
}

function enqueueSuccess({ message }) {
  return enqueueNotification({ message, type: notificationTypes.SUCCESS })
}

function enqueueWarning({ message }) {
  return enqueueNotification({ message, type: notificationTypes.WARNING })
}

function dequeueNotification() {
  return {
    type: actionTypes.DEQUEUE_NOTIFICATION,
  }
}

export {
  enqueueError,
  enqueueInfo,
  enqueueNotification,
  enqueueSuccess,
  enqueueWarning,
  dequeueNotification,
}
