import createReducer from 'lib/reducers/createReducer'
import { actionTypes } from '../constants'

const INITIAL_STATE = []

function dequeueHandler(state, action) {
  return action.payload
    ? state.filter(notification => notification.id !== action.payload.id)
    : state
}

function enqueueHandler(state, action) {
  return action.payload ? state.concat(action.payload) : state
}

const reducer = createReducer(INITIAL_STATE, {
  [actionTypes.ENQUEUE_FLASH_NOTIFICATION]: enqueueHandler,
  [actionTypes.DEQUEUE_FLASH_NOTIFICATION]: dequeueHandler,
})

export default reducer
