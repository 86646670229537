import { applyMiddleware, createStore, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { actionTypes as fnActionTypes } from 'lib/components/FlashNotifications/constants'
import { createRoot } from 'react-dom/client'

import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createMiddleware, createMetaReducer } from 'redux-beacon'

import GoogleTagManager from '@redux-beacon/google-tag-manager'
import eventsMap from '../googleTagManager/eventsMap'

import rootReducers from 'reducers/index'

/* INITIALISE ROUTER MIDDLEWARE */
const history = createBrowserHistory()

/* INITIALISE GOOGLE TAG MANAGER */
const gtm = GoogleTagManager()
const gtmMiddleware = createMiddleware(eventsMap, gtm)
const gtmMetaReducer = createMetaReducer(eventsMap, gtm)
const dataLayerMiddleware = () => next => action => {
  window.dataLayer = window.dataLayer || []
  if (action.type === fnActionTypes.ENQUEUE_FLASH_NOTIFICATION) {
    const el = document.createElement('div')
    const root = createRoot(el)
    const observer = new MutationObserver(() => {
      observer.disconnect()

      const payload = el.innerText
      window.dataLayer.push({ event: action.type, payload })
      root.unmount()
    })
    observer.observe(el, { childList: true, subtree: true })
    root.render(action.payload.detailComponent)
  } else {
    window.dataLayer.push({ event: action.type, payload: action.payload || null })
  }
  return next(action)
}

/* INITIALISE REDUX*/
const middleware = applyMiddleware(
  thunk,
  gtmMiddleware,
  dataLayerMiddleware,
  routerMiddleware(history)
)
const composeEnhancers = composeWithDevTools({})

/* CREATE REDUX STORE */
const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    gtmMetaReducer,
    ...rootReducers(history),
  })

const store = (initialState = {}) => {
  return createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(middleware)
  )
}

export default store
export { history }
