import { post } from 'lib/reduxHelpers/thunks'
import { creditValidityTypes } from 'actions/types'
import { proxyUrl } from 'lib/utils/request'

function fetchCreditValidity(billToLink) {
  let baseType = creditValidityTypes.FETCH_CREDIT_VALIDITY
  let url = proxyUrl({ link: `${billToLink}/credit_validity` })
  let body = {
    _type: 'credit_validity',
    payment_method_symbol: 'http://purl.org/goodrelations/v1#ByInvoice',
  }

  return post({
    baseType,
    url,
    body,
    extractPayload: response => response.data.credit_validity,
  })
}

export { fetchCreditValidity }
