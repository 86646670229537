import React from 'react'
import PropTypes from 'prop-types'

import { enhanceDropdown as enhancer } from './higherOrderComponents'
import DropdownTrigger from './DropdownTrigger'
import DropdownOption from './DropdownOption'

import styles from './styles.scss'

const Dropdown = ({
  options,
  onSelect,
  selectedOptionId,
  onToggle,
  isOpen,
  identifier,
  noOptionsText,
  selectOptionText,
}) => {
  const optionComponents = () => {
    return options.map(opt => {
      return (
        <DropdownOption key={opt.id} id={opt.id} onSelect={onSelect} name={opt.name} />
      )
    })
  }

  const selectedOptionName = () => {
    let name

    if (options && options.length > 0) {
      let selectedOption = options.find(opt => opt.id === selectedOptionId)
      name = selectedOption ? selectedOption.name : selectOptionText || 'Select an option'
    } else {
      name = noOptionsText || 'No options found'
    }

    return name
  }

  return (
    <nav className={styles.nav}>
      <DropdownTrigger
        identifier={identifier}
        onToggle={onToggle}
        name={selectedOptionName()}
        enabled={options && options.length > 0}
        active={isOpen}
      />

      <ul className={`${styles.ul} ${isOpen ? styles.active : ''}`}>
        {optionComponents()}
      </ul>
    </nav>
  )
}

Dropdown.propTypes = {
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedOptionId: PropTypes.string,
  noOptionsText: PropTypes.string,
  selectOptionText: PropTypes.string,
}

export default enhancer(Dropdown, 'Dropdown')
