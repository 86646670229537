const downForMaintenanceTypes = {
  SET_DOWN_FOR_MAINTENANCE: 'SET_DOWN_FOR_MAINTENANCE',
}

const loggedInTypes = {
  FETCH_LOGGED_IN: 'FETCH_LOGGED_IN',
  FETCH_LOGGED_IN_FULFILLED: 'FETCH_LOGGED_IN_FULFILLED',
  FETCH_LOGGED_IN_REJECTED: 'FETCH_LOGGED_IN_REJECTED',
  CLEAR_FETCH_LOGGED_IN: 'CLEAR_FETCH_LOGGED_IN',
}

export { downForMaintenanceTypes, loggedInTypes }
