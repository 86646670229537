import React from 'react'
import PropTypes from 'prop-types'

import Main from 'lib/components/layout/Main'
import ConnectedBanner, { Banner } from 'lib/components/layout/Banner'
import Section from 'lib/components/layout/Section'
import Gutters from 'lib/components/layout/Gutters'
import PaddedBlock from 'lib/components/layout/PaddedBlock'

const DefaultLayout = ({ children, connected = true }) => {
  return (
    <Main>
      {connected ? <ConnectedBanner /> : <Banner />}
      <Section stackedBottom>
        <Gutters>
          <PaddedBlock isDefault>{children}</PaddedBlock>
        </Gutters>
      </Section>
    </Main>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DefaultLayout
