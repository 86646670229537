import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.scss'

const Section = ({ children, small, stacked, stackedBottom }) => {
  const setClasses = () => {
    let outputClass = [styles.section]
    small && outputClass.push(styles.sectionSmall)
    stacked && outputClass.push(styles.sectionStacked)
    stackedBottom && outputClass.push(styles.sectionStackedBottom)

    return outputClass.join(' ')
  }

  return <section className={setClasses()}>{children}</section>
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  small: PropTypes.bool,
  stacked: PropTypes.bool,
  stackedBottom: PropTypes.bool,
}

export default Section
