import createReducer from 'lib/reducers/createReducer'
import { addressesTypes } from 'actions/types'
import { combineReducers } from 'redux'

const INITIAL_STATE = []

function dataHandler(state, action) {
  if (action.payload) {
    return [...state, action.payload]
  } else {
    return INITIAL_STATE
  }
}
const customAddressesReducer = createReducer(INITIAL_STATE, {
  [addressesTypes.ADD_CUSTOM_ADDRESS]: dataHandler,
  [addressesTypes.CLEAR_CUSTOM_ADDRESSES]: dataHandler,
})

const reducer = combineReducers({
  data: customAddressesReducer,
})

export default reducer
