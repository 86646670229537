const BIRTH_ID_RANGE_URN = 'urn:lic:configreq:birth_id_range' // full birth id (ptpt, year, number)
const BIRTH_ID_SEQ_NUMBER_RANGE_URN = 'urn:lic:configreq:birth_id_seq_number_range' // just the number
const BIRTH_ID_URNS = [BIRTH_ID_RANGE_URN, BIRTH_ID_SEQ_NUMBER_RANGE_URN]
const MANAGEMENT_NUMBER_RANGE_URN = 'urn:lic:configreq:mgmt_number_range'
const MANAGEMENT_NUMBER_URNS = [MANAGEMENT_NUMBER_RANGE_URN]
const TAG_NUMBER_REQUIREMENT_URNS = [...BIRTH_ID_URNS, ...MANAGEMENT_NUMBER_URNS]
const PARTICIPANT_CODE_URN = 'urn:lic:configreq:birth_id_prefix'
const TEXT_POSITION_0_URN = 'urn:lic:configreq:text_position_0'
const TEXT_POSITION_1_URN = 'urn:lic:configreq:text_position_1'
const TEXT_POSITION_2_URN = 'urn:lic:configreq:text_position_2'
const TEXT_REQUIREMENT_URNS = [
  TEXT_POSITION_0_URN,
  TEXT_POSITION_1_URN,
  TEXT_POSITION_2_URN,
]
const NAIT_PRODUCTION_TYPE_URN = 'urn:lic:configreq:nait_production_type'
const NAIT_LOCATION_URN = 'urn:lic:configreq:nait_location'
const SPECIES_URN = 'urn:lic:configreq:species'
const ANIMAL_COUNT_URN = 'urn:lic:configreq:animal_count'
const BRASS_TAG_SLUGS = ['allflex-brass-tag', 'zee-tags-brass-tag', 'leader-brass-tag']
const BEEF_TAGS_SLUGS = [
  'allflex-beefprint-tag-female',
  'allflex-beefprint-tag-male',
  'allflex-angus-tag-female',
  'allflex-angus-tag-male',
]

export {
  ANIMAL_COUNT_URN,
  BEEF_TAGS_SLUGS,
  BIRTH_ID_RANGE_URN,
  BIRTH_ID_SEQ_NUMBER_RANGE_URN,
  BIRTH_ID_URNS,
  BRASS_TAG_SLUGS,
  MANAGEMENT_NUMBER_RANGE_URN,
  MANAGEMENT_NUMBER_URNS,
  NAIT_LOCATION_URN,
  NAIT_PRODUCTION_TYPE_URN,
  PARTICIPANT_CODE_URN,
  SPECIES_URN,
  TAG_NUMBER_REQUIREMENT_URNS,
  TEXT_POSITION_0_URN,
  TEXT_POSITION_1_URN,
  TEXT_POSITION_2_URN,
  TEXT_REQUIREMENT_URNS,
}
