import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import injectContext from 'components/higherOrderComponents/contextInjector'

const PublicRoute = ({ component, render, ...rest }) => {
  return <Route {...rest} component={component} render={render} />
}

PublicRoute.propTypes = {
  component: PropTypes.func,
  render: PropTypes.func,
}

export { PublicRoute }
export default injectContext()(PublicRoute)
