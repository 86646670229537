import React, { lazy, Suspense } from 'react'
import productFamilies from 'constants/productFamilies'

const Home = lazy(() => import(/* webpackChunkName: 'Home' */ 'components/Home'))
const Product = lazy(() => import(/* webpackChunkName: 'Product' */ 'components/Product'))
const ProductSearchResults = lazy(() =>
  import(/* webpackChunkName: 'ProductSearchResults' */ 'components/ProductSearchResults')
)
const ProductFamily = lazy(() =>
  import(/* webpackChunkName: 'ProductFamily' */ 'components/ProductFamily')
)

import PublicRoute from './PublicRoute'
import RequestWrapper, { IN_PROGRESS } from '@licnz/react-request-wrapper'

const suspendedComponent = (Component, props) => (
  <Suspense fallback={<RequestWrapper requestState={IN_PROGRESS} />}>
    <Component {...props} />
  </Suspense>
)

const PRODUCT_ROUTES = Object.values(productFamilies)
  .filter(productFamily => productFamily.enabled)
  .map(family => {
    return (
      <PublicRoute
        key={`${family.urn}.product`}
        path={`${family.route}/:id`}
        render={props => suspendedComponent(Product, props)}
      />
    )
  })

const PRODUCTS_ROUTES = Object.values(productFamilies)
  .filter(productFamily => productFamily.enabled)
  .map(family => {
    return (
      <PublicRoute
        key={`${family.urn}.products`}
        path={family.route}
        render={props => suspendedComponent(ProductFamily, { ...props, family })}
      />
    )
  })

const ROUTES = [
  <PublicRoute
    key='home'
    exact
    path='/'
    render={props => suspendedComponent(Home, props)}
  />,
  ...PRODUCT_ROUTES,
  ...PRODUCTS_ROUTES,
  <PublicRoute
    key='search_id'
    path='/search/:id'
    render={props => suspendedComponent(Product, props)}
  />,
  <PublicRoute
    key='search'
    path='/search'
    render={props => suspendedComponent(ProductSearchResults, props)}
  />,
]

export default ROUTES
