import { SET_DUMMY_TAGS_TRUE, SET_DUMMY_TAGS_FALSE } from 'actions/types'

export function dummyTagsReducer(state = false, action) {
    switch (action.type) {
        case SET_DUMMY_TAGS_TRUE:
            return true
        case SET_DUMMY_TAGS_FALSE:
            return false
        default:
            return state
    }
}

export default dummyTagsReducer
