import { href } from '@licnz/js-utils'
import {
  BIRTH_ID_URNS,
  MANAGEMENT_NUMBER_URNS,
  PARTICIPANT_CODE_URN,
} from 'constants/productConfiguration'

let availableSizes = []

const transformConfigurationProperties = (productNode, requestedProductNode) => {
  requestedProductNode.configuration_properties = productNode.config_requirements.map(
    cr => {
      let defaultValue = ''
      if (cr.enumeration && cr.enumeration.length > 0) {
        let defaultSelection = cr.enumeration.find(enumeration => enumeration.default)
        if (defaultSelection) defaultValue = defaultSelection.id
      }
      return { id: cr.identity, value: defaultValue }
    }
  )
}

const transformSizes = (productNode, requestedProductNode) => {
  if (productNode.sizes && productNode.sizes.length > 0) {
    let defaultAvailableSize =
      availableSizes.find(availableSize => availableSize.default) || availableSizes[0]
    let defaultSizeUrn = defaultAvailableSize.component_sizes.find(
      cs => cs.slug === productNode.slug
    ).size

    const sizeId = 'urn:lic:product:size'
    let defaultSize =
      productNode.sizes.find(size => size.identity === defaultSizeUrn) ||
      productNode.sizes[0]

    requestedProductNode.size = {
      id: sizeId,
      value: defaultSize.identity,
      name: defaultSize.display_name,
    }
  }
}

const transformColours = (productNode, requestedProductNode) => {
  if (productNode.colours && productNode.colours.length > 0) {
    const colourId = 'urn:lic:product:colour'
    let defaultColour =
      productNode.colours.find(colour => colour.default) || productNode.colours[0]
    let defaultColourIdentifier = defaultColour.identifiers.find(
      id => id._type === colourId
    ).identifier

    requestedProductNode.colour = {
      id: colourId,
      value: defaultColourIdentifier,
      name: defaultColour.display_name,
    }
  }
}

const transformMarkingOptions = (productNode, requestedProductNode) => {
  if (productNode.marking_options && productNode.marking_options.length > 0) {
    const markingOptionId = 'urn:lic:product:marking_option'
    let defaultMarkingOption = productNode.marking_options.find(mo => mo.default)

    requestedProductNode.marking_option = {
      id: markingOptionId,
      value: defaultMarkingOption.identity,
      configuration_properties: defaultMarkingOption.config_requirements.map(mo => {
        return { id: mo.identity, value: '' }
      }),
    }
  }
}

const transformProductNodeToRequestedProductNode = (
  productNode,
  requestedProductNode
) => {
  requestedProductNode.name = productNode.name
  requestedProductNode.product_id = productNode.id
  requestedProductNode.product_slug = productNode.slug
  requestedProductNode.children = productNode.children.map(() => {
    return {}
  })
  if (productNode.links)
    requestedProductNode.product_link = href({ links: productNode.links, rel: 'self' })

  transformConfigurationProperties(productNode, requestedProductNode)
  transformSizes(productNode, requestedProductNode)
  transformColours(productNode, requestedProductNode)
  transformMarkingOptions(productNode, requestedProductNode)
}

const generateRequestedProduct = (productNodeQueue, requestedProductNodeQueue) => {
  if (productNodeQueue.length === 0) return

  let productNode = productNodeQueue.shift()
  let requestedProductNode = requestedProductNodeQueue.shift()

  transformProductNodeToRequestedProductNode(productNode, requestedProductNode)

  productNode.children.forEach(child => productNodeQueue.push(child))
  requestedProductNode.children.forEach(child => requestedProductNodeQueue.push(child))

  return generateRequestedProduct(productNodeQueue, requestedProductNodeQueue)
}

const processConfigurationNode = (queue, configuration = {}) => {
  if (queue.length === 0) return configuration

  let currentNode = queue.shift()

  let config = { productName: currentNode.name, invoiceDescription: currentNode.invoice_description }
  if (currentNode.sizes && currentNode.sizes.length > 0) config.sizes = currentNode.sizes
  if (currentNode.colours && currentNode.colours.length > 0)
    config.colours = currentNode.colours
  if (currentNode.config_requirements && currentNode.config_requirements.length > 0)
    config.configRequirements = currentNode.config_requirements
  if (currentNode.marking_options && currentNode.marking_options.length > 0)
    config.markingOptions = currentNode.marking_options
  configuration[currentNode.slug] = config

  currentNode.children.forEach(child => queue.push(child))
  return processConfigurationNode(queue, configuration)
}

const extractConfigurationOptions = product => {
  if (!product)
    throw new Error(
      `Error extracting configuration options from Product: product is ${product}`
    )

  return processConfigurationNode([product])
}

const transformProductToRequestedProduct = product => {
  if (!product)
    throw new Error(
      `Error transforming Product to RequestedProduct: product is ${product}`
    )
  availableSizes = product.available_sizes || []

  let requestedProduct = {
    _type: 'requested_product',
    supplier_url: product.supplier_url,
    quantity: product.configurable ? 0 : 1,
    animal_group_reservations: [],
    nait_number_confirmed: false,
    birth_id_replacement: false,
  }

  generateRequestedProduct([product], [requestedProduct])

  return requestedProduct
}

const extractDuplicatedRequirements = (
  queue,
  birthIdRequirements = {},
  managementNumberRequirements = {},
  participantCodeRequirements = {}
) => {
  if (queue.length === 0)
    return {
      birthIdRequirements,
      managementNumberRequirements,
      participantCodeRequirements,
    }

  let currentNode = queue.shift()
  let configRequirements = currentNode.config_requirements

  configRequirements.forEach((cr, index) => {
    let urn = cr.identity
    let path = `${currentNode.path}.configuration_properties[${index}].value`

    if (BIRTH_ID_URNS.includes(urn)) {
      if (birthIdRequirements[urn]) birthIdRequirements[urn].push(path)
      else birthIdRequirements[urn] = [path]
    }
    if (MANAGEMENT_NUMBER_URNS.includes(urn)) {
      if (managementNumberRequirements[urn]) managementNumberRequirements[urn].push(path)
      else managementNumberRequirements[urn] = [path]
    }
    if (urn === PARTICIPANT_CODE_URN) {
      if (participantCodeRequirements[urn]) participantCodeRequirements[urn].push(path)
      else participantCodeRequirements[urn] = [path]
    }
  })

  currentNode.children.forEach((child, index) => {
    let path = currentNode.path
      ? `${currentNode.path}.children[${index}]`
      : `children[${index}]`
    queue.push({ ...child, path })
  })

  return extractDuplicatedRequirements(
    queue,
    birthIdRequirements,
    managementNumberRequirements,
    participantCodeRequirements
  )
}

const updateDuplicatedConfigPaths = ({
  urn,
  addPath,
  removePath,
  currentRequirements,
}) => {
  if (!addPath && !removePath) return

  if (currentRequirements[urn]) {
    let nextPaths = [...currentRequirements[urn]]
    if (removePath) nextPaths = nextPaths.filter(req => req !== removePath)
    if (addPath) nextPaths.push(addPath)

    let nextRequirements = { ...currentRequirements, [urn]: nextPaths }
    if (!nextPaths.length) delete nextRequirements[urn]
    return nextRequirements
  } else {
    if (addPath) return { ...currentRequirements, [urn]: [addPath] }
  }
}

export {
  extractConfigurationOptions,
  extractDuplicatedRequirements,
  transformProductToRequestedProduct,
  updateDuplicatedConfigPaths,
}
