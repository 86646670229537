import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import styles from './styles.scss'

const BasketDropdownOption = ({ link, label }) => {
  return (
    <NavLink className={styles.anchor} to={link}>
      {label}
    </NavLink>
  )
}

BasketDropdownOption.propTypes = {
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default BasketDropdownOption
