import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { href } from '@licnz/js-utils'
import queryString from 'query-string'

import { enqueueError } from 'lib/components/GlobalNotifications'
import { enqueueFlashNotification } from 'lib/components/FlashNotifications/actions/flashNotificationsActions'
import {
  customerContextIsComplete,
  canFetchBasket,
} from 'selectors/customerContextSelectors'
import { fetchBasket } from 'actions/basketActions'
import { fetchBillingEntities } from 'actions/billingEntitiesActions'
import { fetchBillingEntity } from 'actions/billingEntityActions'
import { setCustomerContext } from 'actions/customerContextActions'
import { fetchOperation } from 'actions/operationActions'
import { fetchOperations, searchOperations } from 'actions/operationsActions'
import { fetchOrganisation } from 'actions/organisationActions'
import { fetchPerson } from 'actions/personActions'
import { isImpersonating } from 'selectors/impersonationSelectors'

import Button from 'lib/components/Button'
import Dropdown from 'lib/components/Dropdown'
import ReactModal from 'react-modal'

import styles from './styles.scss'

const customStyles = {
  overlay: {
    animation: 'modal-in 0.15s ease-out 0s backwards',
    backgroundColor: 'rgba(0, 30, 98, .5)',
    zIndex: '4',
  },
}

class CustomerContextHandler extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mustSelectOperation: false,
      selectedOperation: null,
    }

    this.handleSelectOperation = this.handleSelectOperation.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidUpdate(prevProps) {
    let {
      basket,
      billingEntities,
      canFetchBasket,
      customerContext,
      customerContextIsComplete,
      enqueueError,
      enqueueFlashNotification,
      fetchBillingEntities,
      fetchOperations,
      isImpersonating,
      operations,
      requiresOperation,
      searchOperations,
      location: { search },
    } = this.props

    if (
      !prevProps.customerContext &&
      customerContext &&
      customerContext.organisation_party_link
    ) {
      let organisationLink = customerContext.organisation_party_link

      fetchBillingEntities(organisationLink).catch(() =>
        enqueueError({
          message:
            "There was a problem fetching the billing entity for your organisation. You won't be able to add products to your basket. Please try again later.",
        })
      )

      if (isImpersonating) {
        // NOTE AgriManagers use a different API to customers to avoid finding operations via transitive associations
        // Customers do not have access to this API, but the API they use is scoped by their auth token,
        // avoiding the transitive association problem
        searchOperations(organisationLink).catch(() =>
          enqueueError({
            message:
              "There was a problem fetching the farming operations for this organisation. You won't be able to order products requiring a Participant Code. Please try again later.",
          })
        )
      } else {
        fetchOperations(organisationLink).catch(() =>
          enqueueError({
            message:
              "There was a problem fetching the farming operations for your organisation. You won't be able to order products requiring a Participant Code. Please try again later.",
          })
        )
      }
    }

    if (!prevProps.canFetchBasket && canFetchBasket) {
      this.getBasket()
    }

    // Set additional customer context from basket
    if (!prevProps.basket && basket) {
      let billingEntity = basket['urn:lic:predicate:bill_to_party'].identifier.identifier
      this.setBillingEntity(billingEntity)

      let operationParty = basket['urn:lic:predicate:assign_to_party']
      if (operationParty && Object.keys(operationParty).length)
        this.setOperation(operationParty.identifier.identifier)
    }

    const customerSearchLink = global.config.LIC_CUSTOMER_ENDPOINT
    let flashNotificationMessages = []

    // Set the billing party
    if (prevProps.billingEntities !== billingEntities && !customerContext.bill_to_link) {
      // An Organisation will only ever have 0 or 1 billing entities
      if (billingEntities.length === 0) {
        enqueueError({
          message:
            "Your Organisation doesn't have a billing entity. Please contact LIC on 0800 2 MINDA (0800 264 632).",
          ctaLabel: 'Back to customer search.',
          ctaLink: customerSearchLink,
        })
        flashNotificationMessages.push("Your Organisation doesn't have a billing entity.")
      } else this.setBillingEntity(href({ links: billingEntities[0].links, rel: 'self' }))
    }

    let operationLink = queryString.parse(search).operation_id
    if (
      operationLink &&
      customerContext &&
      operationLink !== customerContext.assign_to_link
    ) {
      this.setOperation(operationLink)
    }

    // Dereference the `assign_to_link` when one is set
    if (
      customerContext &&
      customerContext.assign_to_link &&
      customerContext.assign_to_link !==
        (prevProps.customerContext && prevProps.customerContext.assign_to_link)
    ) {
      this.dereferenceAssignToParty()
    }

    if (prevProps.operations !== operations && !operations.length) {
      enqueueError({
        message:
          "Your Organisation doesn't have any Operations. Please contact LIC on 0800 2 MINDA (0800 264 632).",
        ctaLabel: 'Back to customer search.',
        ctaLink: customerSearchLink,
      })
      flashNotificationMessages.push("Your Organisation doesn't have any Operations.")
    }

    if (flashNotificationMessages.length) {
      let linkClasses = `${styles.button} ${styles.buttonPrimary}`
      let detailComponent = (
        <p>
          {flashNotificationMessages.join(' ')} Please contact LIC on 0800 2 MINDA (0800
          264 632). &nbsp;
          <a href={customerSearchLink} className={linkClasses}>
            Back to customer search
          </a>
        </p>
      )
      let id = 'customer_search' + Math.random()
      enqueueFlashNotification({ id: id, detailComponent })
    }

    if (!prevProps.customerContextIsComplete && customerContextIsComplete)
      this.dereferenceBaseContext()

    if (
      (operations.length && !prevProps.requiresOperation && requiresOperation) ||
      (prevProps.operations !== operations && requiresOperation)
    ) {
      if (!customerContext.assign_to_link) {
        if (operations.length === 1)
          this.setOperationParameter(href({ links: operations[0].links, rel: 'self' }))
        else this.setState({ mustSelectOperation: true })
      }
    }

    if (
      prevProps.customerContext &&
      !prevProps.customerContext.assign_to_link &&
      customerContext.assign_to_link
    ) {
      this.setState({ mustSelectOperation: false })
    }
  }

  dereferenceBaseContext() {
    let {
      customerContext,
      fetchBillingEntity,
      fetchOrganisation,
      fetchPerson,
    } = this.props

    fetchOrganisation(customerContext.organisation_party_link).catch(() => {})
    fetchPerson(customerContext.party_link).catch(() => {})
    fetchBillingEntity(customerContext.bill_to_link).catch(() => {})
  }

  dereferenceAssignToParty() {
    let { customerContext, fetchOperation } = this.props
    let operationPartyLink = customerContext.assign_to_link

    fetchOperation(operationPartyLink).catch(() => {})
  }

  getBasket() {
    let { customerContext, fetchBasket } = this.props

    fetchBasket(customerContext)
      .then(response => {
        if (!customerContext.bill_to_link) {
          let billingEntityLink =
            response.data['urn:lic:predicate:bill_to_party'].identifier.identifier
          this.setBillingEntity(billingEntityLink)
        }
      })
      .catch(() => {})
  }

  handleSelectOperation(operationLink) {
    this.setState({ selectedOperation: operationLink })
  }

  handleSubmit() {
    let { selectedOperation } = this.state
    this.setOperationParameter(selectedOperation)
  }

  setBillingEntity(billingEntityLink) {
    let { customerContext, setCustomerContext } = this.props
    setCustomerContext({ ...customerContext, bill_to_link: billingEntityLink })
  }

  setOperation(operationLink) {
    let { customerContext, setCustomerContext } = this.props
    setCustomerContext({ ...customerContext, assign_to_link: operationLink })
  }

  setOperationParameter(operationLink) {
    let {
      history,
      location: { search },
    } = this.props

    let params = queryString.parse(search)
    params.operation_id = operationLink
    let query = `?${queryString.stringify(params)}`
    history.replace(query)
  }

  render() {
    let { selectedOperation } = this.state
    let { organisation, person } = this.props
    let operationOptions = this.props.operations.map(op => {
      let name = `${op.metadata.name} (${op.metadata.handle})`
      return { id: href({ links: op.links, rel: 'self' }), name }
    })

    return this.state.mustSelectOperation &&
      operationOptions.length &&
      person &&
      organisation ? (
      <ReactModal
        isOpen={this.state.mustSelectOperation}
        contentLabel='Operation Select'
        className={styles.content}
        style={customStyles}
        ariaHideApp={false}
      >
        <p>
          {'Purchasing on behalf of ' +
            person.metadata.name +
            ' for ' +
            organisation.metadata.name}
        </p>
        <p>Which Operation would you like to order for?</p>
        <Dropdown
          onSelect={this.handleSelectOperation}
          options={operationOptions}
          selectedOptionId={selectedOperation}
          selectOptionText='Select an operation...'
        />
        <Button className='buttonSecondary buttonBlock' onClick={this.handleSubmit}>
          Continue shopping
        </Button>
      </ReactModal>
    ) : null
  }
}

const mapDispatchToProps = {
  enqueueError,
  enqueueFlashNotification,
  fetchBasket,
  fetchBillingEntities,
  fetchBillingEntity,
  fetchOperation,
  fetchOperations,
  fetchOrganisation,
  fetchPerson,
  searchOperations,
  setCustomerContext,
}

export { CustomerContextHandler }
export default compose(
  connect(state => {
    return {
      basket: state.basket.data,
      billingEntities: state.billingEntities.data,
      canFetchBasket: canFetchBasket(state),
      customerContext: state.customerContext.data,
      customerContextIsComplete: customerContextIsComplete(state),
      isImpersonating: isImpersonating(state),
      operations: state.operations.data,
      organisation: state.customerContext.organisation.data,
      person: state.customerContext.person.data,
      requiresOperation: state.customerContext.requiresOperation,
    }
  }, mapDispatchToProps),
  withRouter
)(CustomerContextHandler)
