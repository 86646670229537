import { combineReducers } from 'redux'
import createReducer from 'lib/reducers/createReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'

import { reservationTypes } from 'actions/types'

const INITIAL_STATE = null

function dataHandler(state, action) {
  if (action.type === reservationTypes.CANCEL_MANAGEMENT_NUMBERS_RESERVATION_FULFILLED)
    return INITIAL_STATE
  else return action.payload || INITIAL_STATE
}

const dataReducer = createReducer(INITIAL_STATE, {
  [reservationTypes.CANCEL_MANAGEMENT_NUMBERS_RESERVATION_FULFILLED]: dataHandler,
  [reservationTypes.CLEAR_FETCH_MANAGEMENT_NUMBERS_RESERVATION]: dataHandler,
  [reservationTypes.FETCH_MANAGEMENT_NUMBERS_RESERVATION_FULFILLED]: dataHandler,
})
const reducer = combineReducers({
  data: dataReducer,
  requestState: requestStateReducer(
    reservationTypes.FETCH_MANAGEMENT_NUMBERS_RESERVATION
  ),
  createRequestState: requestStateReducer(
    reservationTypes.CREATE_MANAGEMENT_NUMBERS_RESERVATION
  ),
  cancelRequestState: requestStateReducer(
    reservationTypes.CANCEL_MANAGEMENT_NUMBERS_RESERVATION
  ),
})

export default reducer
