import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.scss'

const PaddedBlock = ({
  children,
  noBottom,
  small,
  summary,
  invert,
  info,
  hasHeading,
  isDefault,
  noRight,
}) => {
  const setClasses = () => {
    let outputClass = [styles.paddedBlock]
    invert && outputClass.push(styles.paddedBlockInvert)
    info && outputClass.push(styles.paddedBlockInfo)
    noBottom && outputClass.push(styles.paddedBlockNoBottom)
    hasHeading && outputClass.push(styles.paddedBlockHeading)
    small && outputClass.push(styles.paddedBlockSmall)
    summary && outputClass.push(styles.paddedBlockSummary)
    isDefault && outputClass.push(styles.paddedBlockDefault)
    noRight && outputClass.push(styles.paddedBlockNoRight)

    return outputClass.join(' ')
  }

  return <div className={setClasses()}>{children}</div>
}

PaddedBlock.propTypes = {
  children: PropTypes.node,
  noBottom: PropTypes.bool,
  summary: PropTypes.bool,
  invert: PropTypes.bool,
  info: PropTypes.bool,
  hasHeading: PropTypes.bool,
  noRight: PropTypes.bool,
}

export default PaddedBlock
