import { combineReducers } from 'redux'
import createReducer from 'lib/reducers/createReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import { animalGroupTypes } from 'actions/types'

const INITIAL_STATE = null
const baseType = animalGroupTypes.FETCH_ANIMAL_GROUP

function dataHandler(state, action) {
  return action.payload ? { ...state, ...action.payload } : INITIAL_STATE
}

const dataReducer = createReducer(INITIAL_STATE, {
  [animalGroupTypes.FETCH_ANIMAL_GROUP]: dataHandler,
  [animalGroupTypes.FETCH_ANIMAL_GROUP_FULFILLED]: dataHandler,
})

const reducer = combineReducers({
  requestState: requestStateReducer(baseType),
  data: dataReducer,
})

export default reducer
