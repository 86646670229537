import { createSelector } from 'reselect'

const isRegulatedCustomer = createSelector([data => data], state => {
  let operation = state.customerContext.operation.data
  return !operation || (operation && operation.metadata.regulated)
})

const isUnregulatedCustomer = createSelector([data => data], state => {
  let operation = state.customerContext.operation.data
  return operation && !operation.metadata.regulated
})

export { isRegulatedCustomer, isUnregulatedCustomer }
