import React from 'react'
import { NavLink } from 'react-router-dom'

import productFamilies from 'constants/productFamilies'

import styles from './styles.scss'

const AppNav = () => {
  const isActive = (match, location) => {
    if (!match) return false
    return match.isExact || (match.path != '/' && location.pathname.includes(match.path))
  }

  return (
    <ul className={styles.navList}>
      {Object.values(productFamilies)
        .filter(productFamily => productFamily.enabled)
        .map(item => {
          return (
            <li className={styles.navListItem} key={item.urn}>
              <NavLink
                className={styles.navLink}
                activeClassName={styles.linkStateActive}
                isActive={isActive}
                to={item.route}
              >
                {item.title}
              </NavLink>
            </li>
          )
        })}
    </ul>
  )
}

export default AppNav
