import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.scss'

const TitleUnderline = ({ children }) => {
  return (
    <h1 className={styles.titleUnderline}>
      <span className={styles.titleUnderlineSpan}>{children}</span>
    </h1>
  )
}

TitleUnderline.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TitleUnderline
