import { combineReducers } from 'redux'
import birthIdsReducer from './birthIdsReducer'
import managementNumbersReducer from './managementNumbersReducer'

const reducer = combineReducers({
  birthIds: birthIdsReducer,
  managementNumbers: managementNumbersReducer,
})

export default reducer
