import React from 'react'
import { connect } from 'react-redux'

const OrderBreadcrumb = ({ order }) => {
  return <span>{order ? order.order_reference : 'View Order'}</span>
}

export { OrderBreadcrumb }
export default connect(state => {
  return {
    order: state.order.data,
  }
})(OrderBreadcrumb)
