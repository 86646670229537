import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import 'react-dates/initialize'

import { history } from './store/createStore'
import { userInfoData } from 'selectors/userInfoSelectors'
import { setUserScope } from 'utils/monitoringClient'

import GlobalNav from '@licnz/react-global-nav'
import AppBar from 'components/AppBar'
import CustomerContextHandler from 'components/CustomerContextHandler'
import RedirectParamsHandler from 'components/RedirectParamsHandler'
import ReduxFlashNotificationController from 'lib/components/FlashNotifications'
import ReduxNotificationController from 'lib/components/GlobalNotifications'
import {
  ROUTES,
  PROTECTED_ROUTES,
  PublicRoute,
  MaintenanceCheck,
} from 'components/Routes'
import SiteFooter from 'lib/components/SiteFooter'
import UserHandler from 'components/UserHandler'
import VersionManager from 'components/VersionManager'
import PageNotFound from 'components/PageNotFound'

import 'assets/scss/global'

function AppRoot() {
  const profile = useSelector(userInfoData)

  useEffect(() => {
    if (profile.subject) {
      setUserScope(profile)
    }
  }, [profile])

  return (
    <ConnectedRouter history={history}>
      <div>
        <RedirectParamsHandler />
        <CustomerContextHandler />
        <UserHandler />
        <VersionManager />
        <GlobalNav shopLink={global.config.SHOP_FRONTEND_ENDPOINT} />
        <AppBar />
        <ReduxFlashNotificationController />
        <ReduxNotificationController />
        <MaintenanceCheck>
          <Switch>
            {ROUTES}
            {PROTECTED_ROUTES}
            <PublicRoute key='404' path='*' component={PageNotFound} />
          </Switch>
        </MaintenanceCheck>
        <SiteFooter />
      </div>
    </ConnectedRouter>
  )
}

export default AppRoot
