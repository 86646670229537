/* global VERSION */
export const version = VERSION

export const ENVIRONMENTS = {
  DEV: 'development',
  ACCP: 'acceptance',
  PROD: 'production',
}

export const isHostedEnv = function isHostedEnvironment() {
  return Object.values(ENVIRONMENTS).includes(environment)
}

const getEnvironment = function getEnvironmentFromURI() {
  const DEV_HOST = 'shop.dev.lic.co.nz'
  const ACCP_HOST = 'shop.accp.lic.co.nz'
  const PROD_HOST = 'shop.lic.co.nz'

  const hostname = window.location.hostname
  switch (hostname) {
    case DEV_HOST:
      return ENVIRONMENTS.DEV
    case ACCP_HOST:
      return ENVIRONMENTS.ACCP
    case PROD_HOST:
      return ENVIRONMENTS.PROD
    default:
      return 'dragons'
  }
}

export const environment = getEnvironment()
