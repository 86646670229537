// Core dependencies
import React from 'react'
import PropTypes from 'prop-types'

// Components
import BasketDropdownTrigger from './BasketDropdownTrigger'
import BasketDropdownOption from './BasketDropdownOption'
import { enhanceDropdown as enhancer } from './higherOrderComponents'

// Stylesheets
import styles from './styles.scss'

const BasketDropdown = ({ options, onToggle, isOpen, identifier, basketCount }) => {
  const dropdownOptions = () => {
    return options.map(option => {
      return (
        <li key={option.link}>
          <BasketDropdownOption
            key={option.link}
            link={option.link}
            label={option.label}
          />
        </li>
      )
    })
  }

  return (
    <nav className={styles.nav}>
      <BasketDropdownTrigger
        onToggle={onToggle}
        active={isOpen}
        identifier={identifier}
        basketCount={basketCount}
      />
      <ul className={`${styles.ul} ${isOpen ? styles.active : null}`}>
        {dropdownOptions()}
      </ul>
    </nav>
  )
}

BasketDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })
  ).isRequired,
  basketCount: PropTypes.number,
}

export default enhancer(BasketDropdown, 'basketDropdown')
