import axios from 'lib/axios'
import { setDownForMaintenance } from 'lib/actions/downForMaintenanceActions'

function success({ baseType, dispatch, response, extractPayload, nextAction }) {
  let payload = extractPayload ? extractPayload(response) : response.data
  dispatch({ type: `${baseType}_FULFILLED`, payload })
  if (nextAction) dispatch(nextAction(response))
  return response
}

// expectedErrorStatusCodes allows to mark specific http status codes as an expected outcome
// this should skip alerting this specific status code as an error to sentry.
function failure({ baseType, dispatch, error, expectedErrorStatusCodes = [] }) {
  dispatch({ type: `${baseType}_REJECTED`, payload: error })

  if (error.response.status === 503) {
    dispatch(setDownForMaintenance(true))
  } else {
    if (expectedErrorStatusCodes.length > 0) {
      if (expectedErrorStatusCodes.indexOf(error.response.status) !== -1) {
        return
      }
    }
    throw error
  }
}

function get({
  baseType,
  url,
  nextAction,
  extractPayload = null,
  expectedErrorStatusCodes = [],
}) {
  return function (dispatch) {
    dispatch({ type: baseType })
    return axios
      .get(url)
      .then(response => {
        return success({ baseType, dispatch, response, nextAction, extractPayload })
      })
      .catch(error => {
        return failure({ baseType, dispatch, error, expectedErrorStatusCodes })
      })
  }
}

function patch({ baseType, url, body, nextAction, extractPayload = null }) {
  return function (dispatch) {
    dispatch({ type: baseType })
    return axios
      .patch(url, body)
      .then(response => {
        return success({ baseType, dispatch, response, nextAction, extractPayload })
      })
      .catch(error => {
        return failure({ baseType, dispatch, error })
      })
  }
}

function put({ baseType, url, body, nextAction, extractPayload = null }) {
  return function (dispatch) {
    dispatch({ type: baseType })
    return axios
      .put(url, body)
      .then(response => {
        return success({ baseType, dispatch, response, nextAction, extractPayload })
      })
      .catch(error => {
        return failure({ baseType, dispatch, error })
      })
  }
}

function post({ baseType, url, body, nextAction, extractPayload = null }) {
  return function (dispatch) {
    dispatch({ type: baseType })
    return axios
      .post(url, body)
      .then(response => {
        return success({ baseType, dispatch, response, nextAction, extractPayload })
      })
      .catch(error => {
        return failure({ baseType, dispatch, error })
      })
  }
}

function destroy({ baseType, url, body, nextAction }) {
  return function (dispatch) {
    dispatch({ type: baseType })
    return axios
      .delete(url, body)
      .then(response => {
        return success({ baseType, dispatch, response, nextAction })
      })
      .catch(error => {
        return failure({ baseType, dispatch, error })
      })
  }
}

export { get, patch, put, post, destroy }
