import { get } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/request'

import { billingEntityTypes } from 'actions/types'

function fetchBillingEntity(billToLink) {
  let url = proxyUrl({ link: billToLink })
  let baseType = billingEntityTypes.FETCH_BILLING_ENTITY

  return get({ url, baseType })
}

function clearBillingEntity() {
  return { type: billingEntityTypes.CLEAR_FETCH_BILLING_ENTITY }
}

export { fetchBillingEntity, clearBillingEntity }
