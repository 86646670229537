import actionTypes from 'actions/types'
import { loggedInTypes } from 'lib/actions/types'
import { profileTypes, operationTypes } from 'actions/types'
import {
  dataLoad,
  isAuthenticated,
  setVariableTo,
  setUserProfileVars,
  setOperationHandle,
} from './eventDefinitions'

export default {
  [actionTypes.productsTypes.FETCH_PRODUCTS]: dataLoad,
  [actionTypes.customerContextTypes.SET_CUSTOMER_CONTEXT]: setVariableTo(
    'isImpersonating',
    true
  ),
  [actionTypes.customerContextTypes.CLEAR_CUSTOMER_CONTEXT]: setVariableTo(
    'isImpersonating',
    false
  ),
  [loggedInTypes.FETCH_LOGGED_IN_FULFILLED]: isAuthenticated,
  [profileTypes.FETCH_PROFILE_FULFILLED]: setUserProfileVars,
  [operationTypes.FETCH_OPERATION_FULFILLED]: setOperationHandle,
}