import React, { lazy, Suspense } from 'react'

import ProtectedRoute from '../ProtectedRoute'
import RequestWrapper, { IN_PROGRESS } from '@licnz/react-request-wrapper'

const Basket = lazy(() => import(/* webpackChunkName: 'Basket' */ 'components/Basket'))
const Checkout = lazy(() =>
  import(/* webpackChunkName: 'Checkout' */ 'components/Checkout')
)
const ConfiguredProduct = lazy(() =>
  import(
    /* webpackChunkName: 'ConfiguredProduct' */ 'components/Product/ConfiguredProduct'
  )
)
const Order = lazy(() => import(/* webpackChunkName: 'Order' */ 'components/Order'))
const OrderedProduct = lazy(() =>
  import(/* webpackChunkName: 'OrderedProduct' */ 'components/OrderedProduct')
)
const Orders = lazy(() => import(/* webpackChunkName: 'Orders' */ 'components/Orders'))

const suspendedComponent = (Component, props) => (
  <Suspense fallback={<RequestWrapper requestState={IN_PROGRESS} />}>
    <Component {...props} />
  </Suspense>
)

const PROTECTED_ROUTES = [
  <ProtectedRoute
    key='basket_id'
    path='/basket/:id'
    component={props => suspendedComponent(ConfiguredProduct, props)}
  />,
  <ProtectedRoute
    key='basket'
    path='/basket'
    component={props => suspendedComponent(Basket, props)}
  />,
  <ProtectedRoute
    key='checkout'
    path='/checkout'
    component={props => suspendedComponent(Checkout, props)}
  />,
  <ProtectedRoute
    key='orders'
    exact
    path='/orders'
    component={props => suspendedComponent(Orders, props)}
  />,
  <ProtectedRoute
    key='ordered_product'
    path='/orders/:id/orderedProduct/:ordered_product_id'
    component={props => suspendedComponent(OrderedProduct, props)}
  />,
  <ProtectedRoute
    key='order'
    path='/orders/:id'
    component={props => suspendedComponent(Order, props)}
  />,
]

export default PROTECTED_ROUTES
