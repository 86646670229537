import { combineReducers } from 'redux'
import birthIdRequirementsReducer from './birthIdRequirementsReducer'
import managementNumberRequirementsReducer from './managementNumberRequirementsReducer'
import participantCodeRequirementsReducer from './participantCodeRequirementsReducer'

const reducer = combineReducers({
  birthIdRequirements: birthIdRequirementsReducer,
  managementNumberRequirements: managementNumberRequirementsReducer,
  participantCodeRequirements: participantCodeRequirementsReducer,
})

export default reducer
