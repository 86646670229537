import React, { Component } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { captureException } from '@sentry/browser'

import { fetchPermissions } from 'actions/permissionsActions'
import { fetchProfile } from 'actions/profileActions'
import { enqueueError } from 'lib/components/GlobalNotifications'
import LoadingIndicatorOverlay from 'lib/components/LoadingIndicatorOverlay'
import { fetchLoggedIn } from 'lib/actions/loggedInActions'
import { SUCCESS, getRequestStatus } from 'lib/selectors/requestStateSelectors'
import { isImpersonating } from 'selectors/impersonationSelectors'

import FsmHandler from './FsmHandler'
import CustomerHandler from './CustomerHandler'

class UserHandler extends Component {
  componentDidMount() {
    let { enqueueError, fetchLoggedIn } = this.props

    fetchLoggedIn().catch(() => {
      enqueueError({ message: 'We were unable to check your logged in status.' })
    })
  }

  componentDidUpdate(prevProps) {
    let {
      enqueueError,
      fetchPermissions,
      fetchProfile,
      isLoggedIn,
      profile,
      profileRequestStatus,
    } = this.props

    if (!prevProps.isLoggedIn && isLoggedIn && !profile && !profileRequestStatus) {
      fetchProfile().catch(err => {
        if (err.response.status === 404) {
          let redirectParams = {
            redirect_url: global.config.SHOP_FRONTEND_ENDPOINT,
            redirect_label: 'Store',
          }
          window.location = `${
            global.config.LIC_CUSTOMER_ENDPOINT
          }/#/profile/create?${queryString.stringify(redirectParams)}`
        } else {
          enqueueError({ message: 'We were unable to fetch your profile.' })
        }
      })
    }

    if (!prevProps.profile && profile) {
      fetchPermissions().catch(err => {
        captureException(err)
        // This message is intentially vague and similar to the above error message.
        enqueueError({ message: 'There was a problem fetching your profile.' })
      })
    }
  }

  render() {
    if (
      this.props.loggedInRequestStatus !== SUCCESS ||
      (this.isLoggedIn &&
        (this.props.profileRequestStatus !== SUCCESS ||
          (this.profile && this.props.permissionsRequestState !== SUCCESS)))
    ) {
      return <LoadingIndicatorOverlay />
    }
    // Rendering CustomerHandler before the permissions request completes breaks CustomerContextHandler.
    return this.props.isImpersonating ? <FsmHandler /> : <CustomerHandler />
  }
}

const mapDispatchToProps = {
  enqueueError,
  fetchLoggedIn,
  fetchPermissions,
  fetchProfile,
}

export { UserHandler }
export default connect(state => {
  return {
    isImpersonating: isImpersonating(state),
    isLoggedIn: state.loggedIn.data.isLoggedIn,
    profile: state.profile.data,
    loggedInRequestStatus: getRequestStatus(state.loggedIn.requestState),
    profileRequestStatus: getRequestStatus(state.profile.requestState),
    permissionsRequestState: getRequestStatus(state.permissions.requestState),
  }
}, mapDispatchToProps)(UserHandler)
