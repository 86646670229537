import { combineReducers } from 'redux'
import dataReducer from 'lib/reducers/dataReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import paymentMethodsReducer from './paymentMethodsReducer'
import { billingEntityTypes } from 'actions/types'

const initialState = null
const baseType = billingEntityTypes.FETCH_BILLING_ENTITY

const reducer = combineReducers({
  requestState: requestStateReducer(baseType),
  data: dataReducer({ initialState, baseType }),
  paymentMethods: paymentMethodsReducer,
})

export default reducer
