import React from 'react'
import PropTypes from 'prop-types'

import BasketIcon from 'lib/components/BasketIcon'

import styles from './styles.scss'

const BasketDropdownTrigger = ({ identifier, onToggle, active, basketCount }) => {
  const handleToggle = e => {
    e.preventDefault()
    onToggle(identifier)
  }

  const setClasses = () => {
    return active ? `${styles.basketTrigger} ${styles.active}` : styles.basketTrigger
  }

  return (
    <a id={identifier} onClick={handleToggle}>
      <span id={identifier} className={setClasses()}>
        <BasketIcon identifier={identifier} basketCount={basketCount} />
      </span>
    </a>
  )
}

BasketDropdownTrigger.defaultProps = {
  enabled: false,
}

BasketDropdownTrigger.propTypes = {
  active: PropTypes.bool.isRequired,
  identifier: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  basketCount: PropTypes.number,
}

export default BasketDropdownTrigger
