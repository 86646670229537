import { combineReducers } from 'redux'
import createReducer from 'lib/reducers/createReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import { orderTypes } from 'actions/types'

const INITIAL_STATE = null

function dataHandler(state, action) {
  return action.payload || INITIAL_STATE
}

const dataReducer = createReducer(INITIAL_STATE, {
  [orderTypes.CLEAR_FETCH_ORDER]: dataHandler,
  [orderTypes.CREATE_ORDER_FULFILLED]: dataHandler,
  [orderTypes.FETCH_ORDER_FULFILLED]: dataHandler,
})

const reducer = combineReducers({
  data: dataReducer,
  requestState: requestStateReducer(orderTypes.FETCH_ORDER),
  createRequestState: requestStateReducer(orderTypes.CREATE_ORDER),
  cancelRequestState: requestStateReducer(orderTypes.CANCEL_ORDER),
})

export default reducer
