import React from 'react'

import GlobalNav from '@licnz/react-global-nav'
import DefaultLayout from 'lib/components/layout/DefaultLayout'
import TitleUnderline from 'lib/components/TitleUnderline'
import SiteFooter from 'lib/components/SiteFooter'

// TODO: fix this, as globalStylesheet was removed ages ago.
// import { globalStylesheet } from '@licnz/react-sass'
import styles from './styles.scss'

const UnsupportedBrowser = () => {
  return (
    <div>
      <GlobalNav />
      <div className={styles.header}>
        <div className={styles.gutters}>
          <div className={styles.headerContent}>
            <a className={styles.headerLogo} href='/'>
              <img src='/images/shop-logo.svg' alt='LIC Logo' />
            </a>
          </div>
        </div>
      </div>
      <DefaultLayout connected={false}>
        <div className={styles.responsiveWidth}>
          <TitleUnderline>Unsupported Browser</TitleUnderline>
        </div>
        <p className={styles.description}>
          The browser you are using is unsupported for use with the LIC Shop.
        </p>
        <p className={styles.description}>
          We support two major versions back from the latest of the following browsers.
        </p>
        <ul>
          <li>
            <a href='https://www.google.com/chrome/'>Google Chrome</a>
          </li>
          <li>
            <a href='https://support.apple.com/downloads/safari'>Apple Safari</a>
          </li>
          <li>
            <a href='https://www.mozilla.org/en-US/firefox/'>Mozilla Firefox</a>
          </li>
        </ul>
        <p className={styles.description}>
          We will continually review and monitor our customers’ browsing habits and this
          list will evolve with them.
        </p>
      </DefaultLayout>
      <SiteFooter />
    </div>
  )
}

export default UnsupportedBrowser
