/**
 * FEATURE_FLAGS
 * Feature flags set here should always be linked to a task in Jira or Trello. They
 * get set to global.FEATURE_FLAGS so that they can be referenced and used to turn features
 * on or off. They should always resolve to a true or false, respective to the environments
 * in which they are intended to be applied.
 */

import { environment, ENVIRONMENTS } from './env'

export const FEATURE_FLAGS = {
  // SHOP-113
  NEW_PRODUCT_FAMILY: (() => {
    return true
  })(),
  // SHOP-18
  REORDER_PRODUCT_FEATURE: (() => {
    return true
  })(),
  // Trello: https://trello.com/c/rHDT0YRu/304-customer-purchase-order-numbers-improvement
  PO_NUMBER_FEATURE: (() => {
    // If PROD, turn the feature off
    return true
  })(),
  Z_TAGS_BRANDING: (() => {
    // If PROD, turn the feature off
    return true
  })(),
  CANCEL_ORDER_LINE_FEATURE: (() => {
    // SHOP-214
    // If PROD, turn the feature off
    return ![ENVIRONMENTS.PROD].includes(environment)
  })(),
}
