import React, { Component } from 'react'
import { compose } from 'redux'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import queryString from 'query-string'

import { isImpersonating } from 'selectors/impersonationSelectors'

/**
 * This HoC can be used to wrap client side routes to carry the ordering context
 * on those routes. This is to support browser refreshes when ordering in
 * FSM impersonation mode - without this the selected customer's context would
 * be lost on refresh.
 */
const injectContext = ({ testMode = false } = {}) => {
  return ComposedComponent => {
    class ControlledComponent extends Component {
      constructor(props) {
        super(props)

        this.state = {
          redirectWithContextParams: false,
        }
      }

      paramsContainContext() {
        let {
          location: { search },
        } = this.props
        let params = queryString.parse(search)

        return params.person_id && params.organisation_id
      }

      paramsWithContext() {
        let {
          customerContext,
          location: { search },
          redirect,
        } = this.props
        let params = queryString.parse(search)

        if (customerContext) {
          ;(params.person_id = customerContext.party_link),
            (params.organisation_id = customerContext.organisation_party_link),
            (params.operation_id = customerContext.assign_to_link)
        }
        if (redirect) {
          ;(params.redirect_url = redirect.url), (params.redirect_label = redirect.label)
        }

        return params
      }

      render() {
        let {
          location: { pathname, state },
          customerContext,
          isImpersonating,
        } = this.props
        let shouldInjectContext =
          isImpersonating && !this.paramsContainContext() && !!customerContext

        return shouldInjectContext ? (
          <Redirect
            to={{
              pathname,
              search: `?${queryString.stringify(this.paramsWithContext())}`,
              state,
            }}
          />
        ) : (
          <ComposedComponent {...this.props} />
        )
      }
    }

    if (testMode) return ControlledComponent

    return compose(
      connect(state => {
        return {
          profile: state.profile.data,
          customerContext: state.customerContext.data,
          redirect: state.redirect,
          isImpersonating: isImpersonating(state),
        }
      }),
      withRouter
    )(ControlledComponent)
  }
}

export default injectContext
