import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.scss'

const BasketIcon = ({ basketCount, identifier, onClick }) => {
  return (
    <span
      id={identifier}
      className={`${styles.basketIconWrapper} ${
        basketCount > 999 && styles.basketIconWrapperLarge
      }`}
      data-count={basketCount}
      onClick={onClick}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 44 44'
        id={identifier}
        className={styles.basketIcon}
      >
        <g id={identifier} data-name='Group 36' transform='translate(-1820 -297)'>
          <rect
            id={identifier}
            data-name='Rectangle 22'
            className={styles.background}
            width='44'
            height='44'
            rx='22'
            transform='translate(1820 297)'
          />
          <path
            id={identifier}
            className={styles.outline}
            d='M19.96,8.474H17.522L15.148,3.488a2,2,0,1,0-1.078.5l2.137,4.485H5.793L7.93,3.986A1.98,1.98,0,1,0,6.859,3.5L4.484,8.488H2.04A2.041,2.041,0,0,0,0,10.528v1.837A2.042,2.042,0,0,0,2.04,14.4h.026L3.423,20.83a2.39,2.39,0,0,0,.454.955,3.1,3.1,0,0,0,.776.712,3.7,3.7,0,0,0,.976.444,3.92,3.92,0,0,0,1.1.15h8.548a3.89,3.89,0,0,0,1.1-.16,3.661,3.661,0,0,0,.97-.434,3.065,3.065,0,0,0,.777-.712,2.4,2.4,0,0,0,.454-.964L19.934,14.4h.026A2.041,2.041,0,0,0,22,12.356V10.514a2.041,2.041,0,0,0-2.04-2.04ZM13.03,2.032a.785.785,0,1,1,.785.785.785.785,0,0,1-.785-.785ZM8.184,1.246a.785.785,0,1,1-.784.786A.785.785,0,0,1,8.184,1.246Zm12.629,11.1a.85.85,0,0,1-.852.852h-.506a.594.594,0,0,0-.581.475l-1.463,6.89a1.213,1.213,0,0,1-.238.488,1.945,1.945,0,0,1-.474.435,2.552,2.552,0,0,1-.663.294,2.692,2.692,0,0,1-.762.118H6.726a2.692,2.692,0,0,1-.762-.118,2.551,2.551,0,0,1-.662-.294,1.918,1.918,0,0,1-.475-.433,1.23,1.23,0,0,1-.238-.49L3.131,13.683a.594.594,0,0,0-.581-.475H2.04a.852.852,0,0,1-.853-.852V10.514a.853.853,0,0,1,.853-.853H19.96a.85.85,0,0,1,.853.853v1.837Zm-5.344,1.256-.713,5.7a.593.593,0,0,1-.589.52h-.073a.594.594,0,0,1-.516-.662l.713-5.7a.6.6,0,0,1,1.187.147l-.01-.005ZM8.414,19.159a.594.594,0,0,1-.515.662H7.825a.6.6,0,0,1-.589-.52l-.712-5.7a.6.6,0,0,1,1.187-.146l.7,5.7Zm3.18-5.625v5.7a.594.594,0,0,1-1.187,0v-5.7a.594.594,0,0,1,1.187,0Z'
            transform='translate(1831 307.001)'
          />
        </g>
      </svg>
    </span>
  )
}

BasketIcon.propTypes = {
  basketCount: PropTypes.number,
  identifier: PropTypes.string,
  onClick: PropTypes.func,
}

export default BasketIcon
