import { get } from 'lib/reduxHelpers/thunks'
import { operationTypes } from 'actions/types'
import { proxyUrl } from 'lib/utils/request'

function fetchOperation(operationLink) {
  let baseType = operationTypes.FETCH_OPERATION
  let url = proxyUrl({ link: operationLink })

  return get({ baseType, url, extractPayload: response => response.data.party })
}

function clearOperation() {
  return { type: operationTypes.CLEAR_FETCH_OPERATION }
}

export { clearOperation, fetchOperation }
