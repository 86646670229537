import React from 'react'
import { connect } from 'react-redux'

const OrderedProductBreadcrumb = ({ orderedProduct }) => {
  return <span>{orderedProduct ? orderedProduct.name : ''}</span>
}

export { OrderedProductBreadcrumb }
export default connect(state => {
  return {
    orderedProduct: state.orderedProduct.data,
  }
})(OrderedProductBreadcrumb)
