import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import withCustomerTypes from 'components/higherOrderComponents/withCustomerTypes'
import {
  dequeueFlashNotification,
  enqueueFlashNotification,
} from '../actions/flashNotificationsActions'
import { fetchCreditValidity } from 'actions/creditValidityActions'
import { isImpersonating } from 'selectors/impersonationSelectors'

import FlashNotifications from '../FlashNotifications'

class ReduxFlashNotificationController extends Component {
  constructor() {
    super()
  }

  componentDidUpdate(prevProps) {
    let {
      creditValidity,
      customerContext,
      dequeueFlashNotification,
      enqueueFlashNotification,
      fetchCreditValidity,
      isImpersonating,
      isUnregulatedCustomer,
      operation,
      organisation,
      person,
      profile,
      redirect,
    } = this.props

    // Credit validity check
    if (
      (!prevProps.customerContext && customerContext && customerContext.bill_to_link) ||
      (prevProps.customerContext &&
        customerContext &&
        prevProps.customerContext.bill_to_link !== customerContext.bill_to_link)
    ) {
      fetchCreditValidity(customerContext.bill_to_link)
    }

    if (prevProps.creditValidity !== creditValidity && creditValidity === 'not_valid') {
      let detailComponent = (
        <p>
          This account is on suspended services, or is inactive. Please call LIC Credit
          Control on (07) 859 1549 for assistance.
        </p>
      )

      enqueueFlashNotification({
        id: 'stop_service',
        title: 'Suspended Services',
        detailComponent,
      })
    }

    // FSM impersonation check
    if (isImpersonating) {
      if (
        (prevProps.person !== person ||
          prevProps.organisation !== organisation ||
          prevProps.operation !== operation) &&
        person &&
        organisation
      ) {
        let customerName = person.metadata.name
        let organisationName = organisation.metadata.name
        let regulationSnippet = isUnregulatedCustomer ? ' - Tag Only' : ''
        let operationSnippet = operation
          ? ` (${operation.metadata.handle}${regulationSnippet})`
          : ''
        let detailComponent = (
          <p>
            Welcome {profile.preferred_name || profile.preferred_username}. You are
            currently purchasing on behalf of {customerName} for {organisationName}
            {operationSnippet}.
            {redirect.url && redirect.label && (
              <span>
                &nbsp;<a href={redirect.url}>Back to {redirect.label}</a>
              </span>
            )}
          </p>
        )
        let notificationId = 'fsm_impersonation'
        dequeueFlashNotification({ id: notificationId })
        enqueueFlashNotification({ id: notificationId, detailComponent })
      }
    }
  }

  render() {
    let { flashNotifications, showNotifications } = this.props

    if (!showNotifications) return null

    return flashNotifications && flashNotifications.length > 0 ? (
      <FlashNotifications notifications={flashNotifications} />
    ) : null
  }
}

const mapDispatchToProps = {
  dequeueFlashNotification,
  enqueueFlashNotification,
  fetchCreditValidity,
}

export { ReduxFlashNotificationController }
export default compose(
  connect(state => {
    return {
      creditValidity: state.creditValidity.data,
      customerContext: state.customerContext.data,
      flashNotifications: state.flashNotifications,
      isImpersonating: isImpersonating(state),
      operation: state.customerContext.operation.data,
      organisation: state.customerContext.organisation.data,
      person: state.customerContext.person.data,
      profile: state.profile.data,
      redirect: state.redirect,
      showNotifications: !state.downForMaintenance,
    }
  }, mapDispatchToProps),
  withCustomerTypes()
)(ReduxFlashNotificationController)
