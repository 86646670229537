import React from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'

import Button from 'lib/components/Button'

import styles from './styles.scss'

const searchInput = React.createRef()

const SearchBar = ({
  onChange,
  onClose,
  onReset,
  onSubmit,
  showCloseIcon = false,
  value,
}) => {
  let inputProps = {
    type: 'search',
    name: 'term',
    required: true,
    autoComplete: 'off',
    autoFocus: true,
    onChange: e => onChange(e.target.value),
    value,
    placeholder: 'Search for products',
  }

  const focusSearchInput = () => {
    if (searchInput.current) searchInput.current.focus()
  }

  const handleReset = () => {
    onReset()
    focusSearchInput()
  }

  const handleSubmit = e => {
    e.preventDefault()
    onSubmit()
    showCloseIcon && onClose()
  }

  const renderSearchBar = () => {
    return (
      <form className={styles.searchBar}>
        <span className={styles.searchInput}>
          <input
            className={`${styles.searchItem} ${styles.input}`}
            {...inputProps}
            ref={searchInput}
          />
          <button
            className={styles.resetIcon}
            onClick={handleReset}
            type='reset'
          ></button>
        </span>
        <Button
          className='buttonSecondary buttonSmall'
          onClick={handleSubmit}
          type='submit'
        >
          Search shop
        </Button>
        {showCloseIcon && (
          <div className={`${styles.searchItem} ${styles.menuSearch}`}>
            <a className={styles.closeLink} onClick={onClose}>
              <svg className={styles.closeIcon}>
                <use xlinkHref='/images/sprites.svg#cross' />
              </svg>
              Close
            </a>
          </div>
        )}
      </form>
    )
  }

  return (
    <CSSTransition
      appear
      classNames={{
        appear: styles.appear,
        appearActive: styles.appearActive,
      }}
      in
      timeout={120}
    >
      {renderSearchBar()}
    </CSSTransition>
  )
}

SearchBar.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  animate: PropTypes.bool,
  showCloseIcon: PropTypes.bool,
  value: PropTypes.string,
}

export default SearchBar
