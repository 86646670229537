import React from 'react'
import { connect } from 'react-redux'

import {
  isRegulatedCustomer,
  isUnregulatedCustomer,
} from 'selectors/regulatedCustomerSelectors'

const withCustomerTypes = ({ testMode = false } = {}) => {
  return ComposedComponent => {
    const ControlledComponent = props => <ComposedComponent {...props} />

    if (testMode) return ControlledComponent

    return connect(state => {
      return {
        isRegulatedCustomer: isRegulatedCustomer(state),
        isUnregulatedCustomer: isUnregulatedCustomer(state),
      }
    })(ControlledComponent)
  }
}

export default withCustomerTypes
