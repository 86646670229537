import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.scss'

const Main = ({ children }) => {
  return (
    <div className={styles.main} role='main'>
      {children}
    </div>
  )
}

Main.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Main
