import * as Sentry from '@sentry/browser'
import { environment, version, isHostedEnv } from './env'

export const intializeMonitoring = function intializeMonitoringWithSentry() {
  if (isHostedEnv()) {
    Sentry.init({
      dsn: 'https://58cb9090c9ac4892a9e98317cb15f577@o346404.ingest.sentry.io/5222479',
      environment,
      release: `shop@${version}`,
    })
  }
}

export const setUserScope = function setUserScopeWithSentry(profile) {
  if (isHostedEnv()) {
    Sentry.configureScope(function (scope) {
      scope.setUser({ id: profile.subject.id, email: profile.preferred_username })
    })
  }
}
