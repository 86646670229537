import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.scss'

const SearchTrigger = ({ handleOpen }) => {
  const handleClick = () => {
    handleOpen()
  }

  return (
    <div className={styles.menuSearch}>
      <a className={styles.searchLink} onClick={handleClick}>
        <svg className={styles.searchIcon}>
          <use xlinkHref='/images/sprites.svg#search' />
        </svg>
        Search
      </a>
    </div>
  )
}

SearchTrigger.propTypes = {
  handleOpen: PropTypes.func.isRequired,
}

export default SearchTrigger
