import React from 'react'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'

import styles from './styles.scss'

function Login({ location }) {
  let currentQueryParams = queryString.parse(location.search)
  let currentPath = encodeURIComponent(location.pathname)
  let params = queryString.stringify(
    { ...currentQueryParams, path: currentPath },
    { arrayFormat: 'bracket' }
  )

  const loginUrl = `${global.config.UI_PROXY_ENDPOINT}/proxy/connect/identity?${params}`

  return (
    <nav className={styles.nav} id='menu'>
      <a href={loginUrl}>Sign in</a>
    </nav>
  )
}

export { Login }
export default withRouter(Login)
