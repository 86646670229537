import React, { lazy, Suspense } from 'react'
import { useSelector } from 'react-redux'

import RequestWrapper, { IN_PROGRESS } from '@licnz/react-request-wrapper'

const DownForMaintenance = lazy(() =>
  import(/* webpackChunkName: 'DownForMaintenance' */ 'components/DownForMaintenance')
)

const suspendedComponent = (Component, props) => (
  <Suspense fallback={<RequestWrapper requestState={IN_PROGRESS} />}>
    <Component {...props} />
  </Suspense>
)

const MaintenancePage = props => suspendedComponent(DownForMaintenance, props)

const MaintenanceCheck = ({ children }) => {
  const downForMaintenance = useSelector(state => state.downForMaintenance)

  if (downForMaintenance) {
    return <MaintenancePage />
  } else {
    return children
  }
}

export { MaintenancePage }
export default MaintenanceCheck
