import React from 'react'

import Section from 'lib/components/layout/Section'
import Gutters from 'lib/components/layout/Gutters'
import Grid from 'lib/components/layout/Grid'
import PaddedBlock from 'lib/components/layout/PaddedBlock'
import PageHeading from 'lib/components/PageHeading'

import styles from './styles.scss'

function PageHeadingSection({ heading, text = null }) {
  return (
    <Section stackedBottom>
      <Gutters>
        <Grid col>
          <div className={styles.responsiveWidth}>
            <PaddedBlock hasHeading>
              <PageHeading heading={heading} />
              {text && <p className={styles.description}>{text}</p>}
            </PaddedBlock>
          </div>
        </Grid>
      </Gutters>
    </Section>
  )
}

export default PageHeadingSection
