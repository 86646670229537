function typesForBaseType(baseType) {
  return {
    [baseType]: baseType,
    [`${baseType}_FULFILLED`]: `${baseType}_FULFILLED`,
    [`${baseType}_REJECTED`]: `${baseType}_REJECTED`,
    [`CLEAR_${baseType}`]: `CLEAR_${baseType}`,
  }
}

export function makeActionCreator(type, ...argNames) {
  return function (...args) {
    const action = { type }
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index]
    })
    return action
  }
}

const addressesTypes = {
  ...typesForBaseType('FETCH_OPERATION_ADDRESSES'),
  ...typesForBaseType('FETCH_ORGANISATION_ADDRESSES'),
  ADD_CUSTOM_ADDRESS: 'ADD_CUSTOM_ADDRESS',
  CLEAR_CUSTOM_ADDRESSES: 'CLEAR_CUSTOM_ADDRESSES',
}

const agOpTypes = typesForBaseType('FETCH_AG_OP')

const animalGroupTypes = typesForBaseType('FETCH_ANIMAL_GROUP')

const availableTagNumbersTypes = {
  ...typesForBaseType('FETCH_BIRTH_IDS'),
  ...typesForBaseType('FETCH_MANAGEMENT_NUMBERS'),
}

const basketTypes = {
  ...typesForBaseType('FETCH_BASKET'),
  ...typesForBaseType('CREATE_BASKET'),
  ...typesForBaseType('UPDATE_BASKET'),
  ...typesForBaseType('DELETE_BASKET'),
}

const billingEntitiesTypes = typesForBaseType('FETCH_BILLING_ENTITIES')

const billingEntityTypes = typesForBaseType('FETCH_BILLING_ENTITY')

const creditValidityTypes = typesForBaseType('FETCH_CREDIT_VALIDITY')

const customerContextTypes = {
  CLEAR_CUSTOMER_CONTEXT: 'CLEAR_CUSTOMER_CONTEXT',
  SET_CUSTOMER_CONTEXT: 'SET_CUSTOMER_CONTEXT',
  SET_REQUIRES_OPERATION: 'SET_REQUIRES_OPERATION',
}

const herdsTypes = typesForBaseType('FETCH_HERDS')

const lastProductSearchTypes = {
  SET_LAST_PRODUCT_SEARCH: 'SET_LAST_PRODUCT_SEARCH',
}

const operationTypes = typesForBaseType('FETCH_OPERATION')

const operationsTypes = typesForBaseType('FETCH_OPERATIONS')

const orderTypes = {
  ...typesForBaseType('FETCH_ORDER'),
  ...typesForBaseType('CANCEL_ORDER'),
  ...typesForBaseType('CREATE_ORDER'),
}

const orderedProductTypes = {
  ...typesForBaseType('FETCH_ORDERED_PRODUCT'),
  ...typesForBaseType('CANCEL_ORDERED_PRODUCT'),
}

const ordersTypes = typesForBaseType('FETCH_ORDERS')

const organisationTypes = typesForBaseType('FETCH_ORGANISATION')

const organisationsTypes = typesForBaseType('FETCH_ORGANISATIONS')

const paymentMethodsTypes = typesForBaseType('FETCH_PAYMENT_METHODS')

const permissionsTypes = typesForBaseType('FETCH_PERMISSIONS')

const personTypes = typesForBaseType('FETCH_PERSON')

const productTypes = {
  ...typesForBaseType('FETCH_PRODUCT'),
  ...typesForBaseType('FETCH_RELATED_PRODUCTS'),
}

const productConfigurationRequirementsTypes = {
  CLEAR_BIRTH_ID_REQUIREMENTS: 'CLEAR_BIRTH_ID_REQUIREMENTS',
  UPDATE_BIRTH_ID_REQUIREMENTS: 'UPDATE_BIRTH_ID_REQUIREMENTS',
  CLEAR_MANAGEMENT_NUMBER_REQUIREMENTS: 'CLEAR_MANAGEMENT_NUMBER_REQUIREMENTS',
  UPDATE_MANAGEMENT_NUMBER_REQUIREMENTS: 'UPDATE_MANAGEMENT_NUMBER_REQUIREMENTS',
  UPDATE_PARTICIPANT_CODE_REQUIREMENTS: 'UPDATE_PARTICIPANT_CODE_REQUIREMENTS',
  CLEAR_PARTICIPANT_CODE_REQUIREMENTS: 'CLEAR_PARTICIPANT_CODE_REQUIREMENTS',
}

const productsTypes = typesForBaseType('FETCH_PRODUCTS')

const profileTypes = {
  ...typesForBaseType('FETCH_PROFILE'),
  ...typesForBaseType('FETCH_PERSON_PROFILE'),
}

const ratingTypes = typesForBaseType('FETCH_RATING')

const redirectTypes = {
  SET_REDIRECT_LABEL: 'SET_REDIRECT_LABEL',
  SET_REDIRECT_URL: 'SET_REDIRECT_URL',
}

const requestedProductTypes = {
  ...typesForBaseType('FETCH_REQUESTED_PRODUCT'),
  ...typesForBaseType('CREATE_REQUESTED_PRODUCT'),
  ...typesForBaseType('UPDATE_REQUESTED_PRODUCT'),
  ...typesForBaseType('DELETE_REQUESTED_PRODUCT'),
  SET_REQUESTED_PRODUCT: 'SET_REQUESTED_PRODUCT',
  CLEAR_REQUESTED_PRODUCT: 'CLEAR_REQUESTED_PRODUCT',
}

const reservationTypes = {
  ...typesForBaseType('FETCH_BIRTH_IDS_RESERVATION'),
  ...typesForBaseType('CREATE_BIRTH_IDS_RESERVATION'),
  ...typesForBaseType('CANCEL_BIRTH_IDS_RESERVATION'),
  ...typesForBaseType('FETCH_MANAGEMENT_NUMBERS_RESERVATION'),
  ...typesForBaseType('CREATE_MANAGEMENT_NUMBERS_RESERVATION'),
  ...typesForBaseType('CANCEL_MANAGEMENT_NUMBERS_RESERVATION'),
}

const shippingRateTypes = typesForBaseType('CREATE_SHIPPING_RATE')

export const SET_DUMMY_TAGS_TRUE = "reservations/setDummyTagsTrue"
export const SET_DUMMY_TAGS_FALSE = "reservations/setDummyTagsFalse"

export {
  addressesTypes,
  agOpTypes,
  animalGroupTypes,
  availableTagNumbersTypes,
  basketTypes,
  billingEntitiesTypes,
  billingEntityTypes,
  creditValidityTypes,
  customerContextTypes,
  herdsTypes,
  lastProductSearchTypes,
  operationTypes,
  operationsTypes,
  orderTypes,
  orderedProductTypes,
  ordersTypes,
  organisationTypes,
  organisationsTypes,
  paymentMethodsTypes,
  permissionsTypes,
  personTypes,
  productTypes,
  productConfigurationRequirementsTypes,
  productsTypes,
  profileTypes,
  ratingTypes,
  redirectTypes,
  requestedProductTypes,
  reservationTypes,
  shippingRateTypes,
}

export default {
  addressesTypes,
  agOpTypes,
  animalGroupTypes,
  availableTagNumbersTypes,
  basketTypes,
  billingEntitiesTypes,
  billingEntityTypes,
  creditValidityTypes,
  customerContextTypes,
  herdsTypes,
  lastProductSearchTypes,
  operationTypes,
  operationsTypes,
  orderTypes,
  orderedProductTypes,
  ordersTypes,
  organisationTypes,
  organisationsTypes,
  paymentMethodsTypes,
  permissionsTypes,
  personTypes,
  productTypes,
  productConfigurationRequirementsTypes,
  productsTypes,
  profileTypes,
  ratingTypes,
  redirectTypes,
  requestedProductTypes,
  reservationTypes,
  shippingRateTypes,
}
