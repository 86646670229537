import React from 'react'
import PropTypes from 'prop-types'

import Gutters from 'lib/components/layout/Gutters'

import styles from './styles.scss'

const FlashNotifications = ({ notifications }) => {
  const renderNotification = notification => {
    return (
      <div key={notification.id} className={styles.notificationContent}>
        <div className={styles.richText}>
          {notification.title && <h5>{notification.title}</h5>}
          {notification.detailComponent}
        </div>
      </div>
    )
  }

  return (
    <div className={`${styles.notificationsWrapper} ${styles.theme}`}>
      <div className={styles.notification}>
        <Gutters noGuttersSmall={false}>
          {notifications.map(notification => {
            return renderNotification(notification)
          })}
        </Gutters>
      </div>
    </div>
  )
}

FlashNotifications.propTypes = {
  notifications: PropTypes.array.isRequired,
}

export default FlashNotifications
