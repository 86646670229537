import { combineReducers } from 'redux'
import dataReducer from 'lib/reducers/dataReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import { ordersTypes } from 'actions/types'

const initialState = []
const baseType = ordersTypes.FETCH_ORDERS

const reducer = combineReducers({
  requestState: requestStateReducer(baseType),
  data: dataReducer({ initialState, baseType }),
})

export default reducer
