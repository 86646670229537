import { FEATURE_FLAGS } from 'utils/featureFlags'

const productFamilies = {
  TAG: {
    enabled: true,
    route: '/tags',
    title: 'Tags',
    snippet:
      'Check out our full range of official NAIT approved electronic identification (EID) tags and extensive range of management tags from Allflex, Z Tags, and Flexa.',
    urn: 'urn:lic:id:product_family:tag',
    imageLink: '/images/tags.jpg',
    filters: [
      {
        title: 'Brand',
        id: 'brand',
        options: [
          { id: 'allflex', name: 'Allflex' },
          { id: 'flexa', name: 'Flexa' },
          { id: 'zee-tags', name: FEATURE_FLAGS.Z_TAGS_BRANDING ? 'Z Tags' : 'Zee Tags' },
        ],
      },
      {
        title: 'Animal type',
        id: 'animal_type',
        options: [
          { id: 'calf', name: 'Calf' },
          { id: 'cow', name: 'Cow' },
          { id: 'deer', name: 'Deer' },
          { id: 'goat', name: 'Goat' },
          { id: 'beef', name: 'Beef' },
        ],
      },
      {
        title: 'Tag type',
        id: 'tag_type',
        options: [
          { id: 'eid', name: 'EID' },
          { id: 'premium', name: 'Premium' },
          { id: 'economy', name: 'Economy' },
          { id: 'accessory', name: 'Accessory' },
        ],
      },
    ],
  },
  HEAT_DETECTION: {
    enabled: true,
    route: '/heat-detection',
    title: 'Heat detection',
    snippet:
      'Identify more cows in heat and improve your artificial insemination (AI) rates with our heat detection patches.',
    urn: 'urn:lic:id:product_family:heat_detection',
    imageLink: '/images/heat-detection.jpg',
    filters: [
      {
        title: 'Brand',
        id: 'brand',
        options: [
          { id: 'bulls-i', name: 'Bulls-i' },
          { id: 'lic', name: 'LIC' },
          { id: 'kamar', name: 'Kamar' },
        ],
      },
    ],
  },
  AB_DIY_EQUIPMENT: {
    enabled: true,
    route: '/ab-diy',
    title: 'AB DIY',
    snippet: 'View our range of equipment suitable for DIY artificial insemination.',
    urn: 'urn:lic:id:product_family:ab_diy_equipment',
    imageLink: '/images/ab-diy-equipment.jpg',
  },
  DNA_TESTING: {
    enabled: true,
    route: '/dna',
    title: 'DNA',
    snippet: 'Purchase equipment for our GeneMark DNA parentage testing service.',
    urn: 'urn:lic:id:product_family:dna_testing',
    imageLink: '/images/dna-testing.jpg',
  },
  FARM_ACCESSORIES: {
    enabled: FEATURE_FLAGS.NEW_PRODUCT_FAMILY,
    route: '/farm-accessories',
    title: 'Farm Accessories',
    snippet:
      'Browse our selection of farm accessories, designed to help with tasks on farm.',
    urn: 'urn:lic:id:product_family:farm_accessories',
    imageLink: '/images/farm-accessories.jpg',
  },
  EID_READERS: {
    enabled: FEATURE_FLAGS.NEW_PRODUCT_FAMILY,
    route: '/eid-readers',
    title: 'EID Readers',
    snippet:
      'Identify animals quickly and easily using a NAIT compatible EID reader or wand.',
    urn: 'urn:lic:id:product_family:eid_readers',
    imageLink: '/images/eid-readers.jpg',
  },
}

const productFamily = urn => {
  let key = Object.keys(productFamilies).find(key => productFamilies[key].urn === urn)
  return productFamilies[key]
}

const titleForUrn = urn => {
  let obj = productFamily(urn)
  if (!obj) console.log(`TitleForUrn: no mapping found for ${urn}`)
  else return obj.title
}

const routeForUrn = urn => {
  let obj = productFamily(urn)
  if (!obj) console.log(`RouteForUrn: no mapping found for ${urn}`)
  else return obj.route
}

const generateCombinedFilters = () => {
  let combinedFilters = []
  // Maps over the values of the productFamilies object
  Object.values(productFamilies).forEach(currentFamily => {
    // Maps over the current family filters if present
    currentFamily.filters &&
      currentFamily.filters.forEach(currentFilter => {
        // Checks to see if there is already a filter in the combinedFilters
        // array with an id that matches the currentFilter's id
        let duplicateFilter = combinedFilters.find(f => f.id === currentFilter.id)
        if (duplicateFilter) {
          // Maps over the current (duplicate) filters options
          currentFilter.options.forEach(currentOption => {
            // Checks to see if there is already an option in the duplicateFilter's
            // options array with an id that matches the currentOption's id
            let duplicateOption = duplicateFilter.options.find(
              opt => opt.id === currentOption.id
            )
            // If the currentOption is not a duplicate, add it to the duplicateFilter options
            if (!duplicateOption)
              duplicateFilter.options = [...duplicateFilter.options, currentOption]
          })
        } else {
          // If the currentFilter is not a duplicate, add it to the combinedFilters array
          combinedFilters.push({ ...currentFilter })
        }
      })
  })
  return combinedFilters
}

export default productFamilies
export { generateCombinedFilters, routeForUrn, titleForUrn }
